<template>
  <div id="admin-layout">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-2 col-md-2 wf-back admin-nav">
          <img class="admin-logo" src="../../assets/wellfit-logo@2x.png" />

          <nav class="nav flex-column mt-4">
            <router-link
              :to="{ name: 'Users' }"
              class="nav-link pl-0"
              @click.native="loadPage('users', 0)"
              >Users</router-link
            >
            <router-link
              :to="{ name: 'Tags' }"
              class="nav-link pl-0"
              @click.native="loadPage('tags', 0)"
              >Tags</router-link
            >
            <router-link
              :to="{ name: 'Seasons' }"
              class="nav-link pl-0"
              @click.native="loadPage('seasons', 2)"
              >Seasons</router-link
            >
            <router-link
              :to="{ name: 'Sessions' }"
              class="nav-link pl-0"
              @click.native="loadPage('sessions', 1)"
              >Sessions</router-link
            >
            <!-- <router-link :to="{ name: 'Categories'}"  class="nav-link pl-0" @click.native="loadPage('categpries',3)">Categories</router-link> -->
            <router-link
              :to="{ name: 'Programs' }"
              class="nav-link pl-0"
              @click.native="loadPage('programs', 4)"
              >Programs</router-link
            >
            <router-link
              :to="{ name: 'APlans' }"
              class="nav-link pl-0"
              @click.native="loadPage('plans', 5)"
              >Plans</router-link
            >
            <router-link
              :to="{ name: 'Ebooks' }"
              class="nav-link pl-0"
              @click.native="loadPage('ebooks', 6)"
              >Links</router-link
            >
            <router-link
              :to="{ name: 'Transactions' }"
              :class="
                this.$route.name == 'Transactions'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0'
              "
              @click.native="loadPage('transactions', 7)"
              >Transactions</router-link
            >
             <router-link
              :to="{ name: 'Settings' }"
              :class="
                this.$route.name == 'Settings'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('settings', 8)"
              >Settings</router-link
            >
            <router-link
              :to="{ name: 'AAccount' }"
              :class="
                this.$route.name == 'AAccount'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('account', 9)"
              >My Account</router-link
            >
          </nav>
        </div>
        <div class="col-lg-10 admin-content pb-0 mb-0 col-md-10">
          <div class="adin">
            <div  v-if="salert"
              class="alert wal alert-dismissible fade show"
              :class="alert.type"
              role="alert"
              :style="alert.type ? 'display:block;position:fixed ' : 'display:none'"
            >
            <div id="alert" >
              <p class="alert-heading m-0">
                <strong>{{ alert.head }}</strong>
                <span class="alert-text ml-2">{{ alert.text }}</span>
              </p>
            </div>
            </div>
            <div class="user d-block text-right">
              <a
                class="dropdown-toggle admenu"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img class="mr-2" v-if="avatar" :src="avatar" />
                <p class="mb-0 d-inline" v-if="user.firstname && user.lastname">
                  {{ user.firstname }} {{ user.lastname }}
                </p>
                <p class="mb-0 d-inline" v-else>{{ user.name }}</p>
                <i class="fas fa-angle-down addrop"></i>
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item ad" @click="logout()"
                  >Log Out</a
                >
              </div>
            </div>
            <router-view />
          </div>
          <div id="sess-footer">
            <div class="foot-details">
                 <span class="pby">powered by</span>
              <img class="powered-logo" src="../../assets/can.png" />
              <p class="mb-0 d-inline-block ml-3 res">
                2020 WellFit Ltd. All Rights Reserved.
                <br />
                <a href="/">Privacy Policy </a>/ <a href="/">Terms of Use</a>
              <a
                class="ogs"
                href="https://www.onegreatstudio.com/"
                target="_blank"
              >
                <img class="" src="../../assets/1gs-logo@2x.png" />
              </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import custom from "../../javascript/custom";
import router from "@/router";
import AdminService from "../../services/adminService";
import axios from "axios";

export default {
  name: "AdminPage",
  data() {
    return {
      page: "",
      user: {},
      avatar: "",
      window: {
        width: 0,
        height: 0,
      },
      alert: {
        text: "",
        head: "",
        type: "",
      },
          salert:false
    };
  },
  created() {
    this.user = this.$session.get("user");

  },
  computed: {},
  mounted() {
    this.getUser();
    this.$bus.$on("userup", () => {
      this.getUser();
    });
    this.$bus.$on("flash", (data) => {
      this.alert.text = data.text;
      if(this.alert.text[0]!=""){
          this.alert.text=this.alert.text[0]
      }
      this.alert.head = data.head;
      this.alert.type = data.type;
       this.salert=true;
         setTimeout(function () {
           if( document.getElementById("alert") != null){
             document.getElementById("alert").classList.remove("d-block");
           }
        this.removeAl()
      }.bind(this), 5000);
    });
    
  },

  components: {},
  methods: {
     removeAl(){
      this.salert=false;
      delete this.alert.type
    },

    loadPage(pg, na) {
      this.page = pg;
      custom.updateNav(na);
    },
    logout() {
      this.$session.clear();
      localStorage.clear();
      sessionStorage.clear();
      this.$session.destroy()
      router.push({ path: "/" });
    },
    async getUser() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");

      try {
        const response = await AdminService.showUser(this.user.id);
        if (response.data.success == true) {
          this.avatar = response.data.data.user.avatar_urls.large;
          this.user.firstname = response.data.data.user.firstname
          this.user.lastname = response.data.data.user.lastname
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
         if((err.response.status)==401){

         this.logout()   
        }
      }
    },

  },
};
</script>