<template>
  <div>
     <div class="table-list users-list mt-4">
       <div class="row">
         <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0"><b>Sessions</b></h4>
          <router-link to="/admin/sessions/new" ><button class="form-btn ml-4">Add New</button></router-link>
          
         </div>
          <div class="col-lg-6 col-md-6 text-right rbtn">  
            <router-link to="/admin/programs" ><button class="form-btn">Manage Programs</button></router-link>
         </div>
       </div>
      <!-- <paginate
        name="orderedUsers"
        :list="sessions"
        :per="lim"
        class="pl-0"
      > -->
        
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Program</th>
              <th scope="col">Platform</th>
              <th scope="col">Instructor</th>
              <th>Premier Date</th>
            </tr>
          </thead>
          <tbody>
            <router-link  class="link_row" :to="{ name: 'EditSession', params: { id: us.id }}" v-for="(us,index) in sessions " :key="user+index">
            <tr class="table-det spacer">
              <td>{{us.title}}</td>
              <td>{{us.program.name}}</td>
              <td>{{us.content_host_type}}</td>
              <td v-if="us.presenter">{{us.presenter.name}}</td>
              <td>{{moment(us.start_at).format('LL')}}
                 <font-awesome-icon  v-on:click.prevent="remItem(us.id)" class="float-right" :icon="['fal', 'times']" />
              </td>
            </tr>
            </router-link>
          </tbody>
        </table>
      <!-- </paginate> -->
      <!-- <paginate-links class="pl-0" for="orderedUsers" :async="true" :limit="lim" :show-step-links="true"></paginate-links> -->
   <ul class="pl-0 paginate-links">
     <li v-if="pages>0"  @click="getSessions(1)" class="left-arrow disabled">
       <a>«</a>
       </li>

       <li @click="getSessions(p)" :class="p == current ? 'number active pg' : 'number pg'" v-for="(p,index) in pages " :key="pag+index" >
       <span   v-if="Math.abs(p - current) < 4 || p == pages  || p== 1" >
         
         <a :class="{current: current=== p, last: (p == pages  && Math.abs(p- current) > 4), first:(p == 1 && Math.abs(p - current) > 4)}" >{{p}}</a>
       </span>
         </li>
       <li  v-if="pages>0" @click="getSessions(pages)" class="right-arrow disabled"><a>»</a></li>
    </ul>
   
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'

export default {
  name: 'SessionsPage',
  data () {
    return {    
      paginate: ['orderedUsers'],
      lim:10,
      sessions:[],
      current:1,
      pages:0,
      // orderedUsers:[]
    }
  },
  created(){
    },
  computed:{
    // orderedUsers: function () {
    //   return this._.orderBy(this.sessions, 'id')
    // }
  },
  mounted(){
  this.getSessions(this.current)
  }, 
  components: {
  },      
  methods: {

     async getSessions(page){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getSessions(page);
        if(response.data.success == true){
          this.sessions = (response.data.data)      
          this.pages=   response.data.pagination.total_pages
          this.current = response.data.pagination.page
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
      remItem(litem){
      if(confirm("Do you really want to delete?")){
        this.remove(litem)
      }
    },
    async remove(it){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.deleteSession(it);
        if(response.data.success == true){
          this.getSessions()
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>