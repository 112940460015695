import $ from 'jquery' //import jQuery 


 export default {

  showPassword: function (x){
    $('span svg').eq(x).removeClass("fa-eye")
    $('span svg').eq(x).addClass("fa-eye-slash")
  },
  hidePassword: function (x){
    $('span svg').eq(x).removeClass("fa-eye-slash")
    $('span svg').eq(x).addClass("fa-eye")
  },
  validatePassword: function (x){
    if(x==false){
      $('.nomatch').css('display','block');
      $('.nomatch').css('max-height','15px')
    }else{
       $('.nomatch').css('display','none');
      $('.nomatch').css('max-height','0px')
    }
  },
  updateNav:function(lik){
    $(".nav").find(".active").removeClass("active");
    $(".nav-link").eq(lik).addClass("active");
  },
  updateAccNav:function(lik){
    $(".nav").find(".active").removeClass("active");
    $(".sidenav .nav-link").eq(lik).addClass("active");
  },
  norenew:function(){
    $('.repl').removeClass('rplan')
    $('.repl').addClass('cplan')
  },
  autorenew:function(){
    $('.repl').addClass('rplan')
    $('.repl').removeClass('cplan')
  },
  
 }