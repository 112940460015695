<template>
  <div class="main-det">
    <div>
      <div class="today mt-4" v-if="week.length > 0">
        <div class="col-sm-12 p-0">
          <div class="row m-0">
            <h5>Classes Happening This Week</h5>
            <button
              :disabled="week.length > 0 && sT == 0"
              class="btn-link prev btn"
              @click="prevToday()"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button
              :disabled="week.length > 0 && week.length <= sT+1"
              class="btn-link btn"
              @click="nextToday()"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
        <!-- <div v-if="week.length==0">
          <h3 class="text-center p-5">Nothing is Classes Happening This Week</h3>
        </div> -->
        <div class="row erow m-0 w-100">
          <div
            v-for="(ins, index) in week.slice(sT)"
            :key="'ht' + index"
            class="htoday col-lg-4 col-md-6"
          >
            <router-link
              v-if="
                ins.content_host_type == 'VimeoVideo' ||
                ins.content_host_type == 'WellFitVideo'
              "
              :to="{ name: 'ShowEpisode', params: { id: ins.id } }"
            >
              <div class="">
                <img class="w-100" :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
                 <p class="d-inline-block mb-0">{{ ins.title }}</p>
                    <!-- <span class="live" v-if="ins.scheme == 'live'">
                      <img class="live-md" src="../../../assets/live.png"
                    /></span> -->
                    <p class="d-inline-block mb-1 sesstime">
                      {{
                        moment(ins.start_at)
                          .tz(moment.tz.guess(true))
                          .format("dddd MMMM DD, YYYY h:mmA z")
                      }}
                    </p>
              </div>
            </router-link>
            <a v-else target="_blank" :href="ins.content_url">
              <div class="">
                <img class="w-100" :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
                 <p class="d-inline-block mb-0">{{ ins.title }}</p>
                    <!-- <span class="live" v-if="ins.scheme == 'live'">
                      <img class="live-md" src="../../../assets/live.png"
                    /></span> -->
                    <p class="d-inline-block mb-1 sesstime">
                      {{
                        moment(ins.start_at)
                          .tz(moment.tz.guess(true))
                          .format("dddd MMMM DD, YYYY h:mmA z")
                      }}
                    </p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="mt-4 today">
         <h5 v-if="sessions.length>0">Recent Sessions</h5>
           <!-- <paginate name="sessions" :list="sessions" :per="lim" class="w-100 p-0"> -->
            <div class="row m-0 pgblock">
              <div
                v-for="(ins, index) in sessions"
                :key="'ht' + index"
                class="htod col-lg-4 col-md-6 pl-0"
              >
                <router-link
                  v-if="
                    ins.content_host_type == 'VimeoVideo' ||
                    ins.content_host_type == 'WellFitVideo'
                  "
                  :to="{ name: 'ShowEpisode', params: { id: ins.id } }"
                >
                  <div class="">
                    <img class="w-100" :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
                    <p class="d-inline-block w-95">
                      {{ ins.title }}
                      {{
                        moment(ins.start_at)
                          .tz(moment.tz.guess(true))
                          .format("dddd MMMM DD, YYYY h:mmA z")
                      }}
                    </p>
                    <font-awesome-icon
                      class="float-right probook"
                      v-if="ins.bookmarked == false"
                      v-on:click.prevent="addVideo(ins.id)"
                      :icon="['far', 'bookmark']"
                    />
                    <font-awesome-icon
                      class="float-right probook"
                      v-else
                      v-on:click.prevent="removeVideo(ins.id)"
                      :icon="['fas', 'bookmark']"
                    />
                    <!-- <span class="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../assets/live.png"/></span> -->
                  </div>
                </router-link>
                <a v-else target="_blank" :href="ins.content_url">
                  <div class="">
                    <img class="w-100" :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
                    <!-- <p class="d-inline-block w-95">{{ins.title}} {{moment(ins.start_at).format('LT')}} {{moment.tz(ins.start_at).format('z')}}
              </p> -->
                    <p class="d-inline-block w-95">
                      {{ ins.title }}
                      {{
                        moment(ins.start_at)
                          .tz(moment.tz.guess(true))
                          .format("dddd MMMM DD, YYYY h:mmA z")
                      }}
                    </p>
                    <!-- <span class="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../assets/live.png"/></span> -->
                    <font-awesome-icon
                      class="float-right probook"
                      v-if="ins.bookmarked == false"
                      v-on:click.prevent="addVideo(ins.id)"
                      :icon="['far', 'bookmark']"
                    />
                    <font-awesome-icon
                      class="float-right probook"
                      v-else
                      v-on:click.prevent="removeVideo(ins.id)"
                      :icon="['fas', 'bookmark']"
                    />
                    <!-- <span class="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../assets/live.png"/></span> -->
                  </div>
                </a>
              </div>
            </div>
          <!-- </paginate> -->
          <!-- <paginate-links
            class="progpag inspag pl-0"
            for="sessions"
            :async="true"
            :limit="lim"
            :show-step-links="true"
          >
          </paginate-links> -->
      </div>
      <div class="workouts" v-if="items.length > 0">
        <div class="col-sm-12 p-0">
          <div class="row m-0">
            <h5>Other Sessions</h5>
            <button
              :disabled="items.length > 0 && sW == 0"
              class="btn-link btn prev"
              @click="prevWork()"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button
              :disabled="items.length > 0 && items.length <= sW+1"
              class="btn-link btn"
              @click="nextWork()"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
        <div class="row m-0 erow w-100">
          <router-link
            class="col-lg-2 pl-0"
            :to="{ name: 'Episodes', params: { id: ins.id } }"
            v-for="(ins, index) in items.slice(sW)"
            :key="'category' + index"
          >
            <div class="workout">
              <img :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
              <!-- <p >{{ins.name}}</p> -->
            </div>
          </router-link>
        </div>
      </div>
      <div class="ebooks" v-if="books.length > 0">
        <div class="col-sm-12 p-0">
          <div class="row m-0">
            <h5>Downloads & Links</h5>
            <button
              :disabled="books.length > 0 && sB == 0"
              class="btn-link btn prev"
              @click="prevBook()"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button
              :disabled="books.length > 0 && books.length <= sB+1"
              class="btn-link btn"
              @click="nextBook()"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
        <div class="row erow m-0">
          <a
            target="_blank"
            :href="book.content_url"
            class="eblk col-lg-3"
            v-for="(book, index) in books.slice(sB)"
            :key="'book' + index"
          >
            <div>
              <div class="row arow m-0">
                <img  :src="book.thumbnail_urls ?  book.thumbnail_urls.large : '' "  />
                <h4 class="mb-0 ml-3">
                  {{ book.title }}
                  <p>{{ book.author }}</p>
                </h4>
                <!-- <i v-if="book.title==1" @click="addBook(book.id)" class="far fa-bookmark"></i>
                   <i v-else @click="removeBook(book.id)" class="fas fa-bookmark"></i> -->
                <font-awesome-icon
                  class="float-right"
                  v-if="book.bookmarked == false"
                  v-on:click.prevent="addBook(book.id)"
                  :icon="['far', 'bookmark']"
                />
                <font-awesome-icon
                  class="float-right"
                  v-else
                  v-on:click.prevent="removeBook(book.id)"
                  :icon="['fas', 'bookmark']"
                />
              </div>
              <p>{{ book.description }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../../services/userService";
import axios from "axios";
import moment from "moment";
import router from "@/router";


export default {
  name: "MasterclassPage",
  data() {
    return {
      page: "",
      sI: 0,
      sT: 0,
      sW: 0,
      sR: 0,
      sB: 0,
      items: [],
      week: [],
      sessions: [],
      books: [],
       paginate: ["sessions"],
      lim: 12,
      tag: "masterclass",
       current:1,
      pages:0,
    };
  },
  created() {
    // this.page=this.$route.params.cat
  },
  mounted() {
    // this.page=this.$route.params.cat
    // this.getWeek();
    // this.getSession(this.current);
    // this.getPrograms();
    // this.getBooks();
    this.getProfile()
  },
  computed: {},
  components: {},
  methods: {
      nextBook() {
        this.sB += 1;
    },
    prevBook() {
        this.sB -= 1;
    },
    nextIns() {
        this.sI += 1;
    },
    prevIns() {
        this.sI -= 1;
    },
    nextToday() {
        this.sT += 1;
    },
    prevToday() {
        this.sT -= 1;
    },
    nextWork() {
        this.sW += 1;
    },
    prevWork() {
        this.sW -= 1;
    },
    nextRecent() {
        this.sR += 1;
    },
    prevRecent() {
        this.sR -= 1;
    },
      async getProfile() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.id);
        if (response.data.success == true) {
          var plan = response.data.data.profile.current_subscription;
          if (plan != null) {
          this.getWeek();
  this.getSession(this.current);
  this.getPrograms();
  this.getBooks()
   this.$session.set("subscription",true)
          } else {
             this.$session.set("subscription",false)
            router.push({ path: "/customer/plans" });
          }
          // this.user.profile.avatar = response.data.data.profile.avatar.large
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async addBook(bid) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.saveBook(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getBooks();
          alert = {
            text: [],
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error");
          alert = {
            text: response.data.errors[0],
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async removeBook(bid) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.removeBook(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getBooks();
          alert = {
            text: [],
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error");
          alert = {
            text: response.data.errors[0],
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getWeek() {
      var start = moment().tz('America/New_York').subtract(2, 'hours').format("YYYY-MM-DD HH:mm:ss");
      var end = moment().tz('America/New_York').add(6, "day").format("YYYY-MM-DD");
      //  console.log(start,end)
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.weekSessions(
          start,
          end,
          "&tags=" + this.tag
        );
        if (response.data.success == true) {
          var sess = [];
          sess = response.data.data;
          for (var i = 0; i < sess.length; i++) {
            if (sess[i].scheme == "live") {
              this.week.push(sess[i]);
            }
          }
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getPrograms() {
      //  console.log(start,end)
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getTagPrograms(this.tag);
        if (response.data.success == true) {
          this.items = response.data.data;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getBooks() {
      //  console.log(start,end)
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getTagBooks(this.tag);
        if (response.data.success == true) {
          this.books = response.data.data;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getSession(page) {
      //  console.log(start,end)
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getTagSessions(this.tag,page);
        if (response.data.success == true) {
          var sess = [];
          sess = response.data.data;
             this.pages=   response.data.pagination.total_pages
          this.current = response.data.pagination.page     
          for (var i = 0; i < sess.length; i++) {
            if (sess[i].scheme == "recorded") {
              this.sessions.push(sess[i]);
            }
          }
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
  },
};
</script>