<template>
  <div>
     <div class="table-list users-list mt-4">
       <div class="row">
         <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0"><b>Seasons</b></h4>
          <router-link to="/admin/seasons/new" ><button class="form-btn ml-4">Add New</button></router-link>
          
         </div>
       </div>
      <!-- <paginate
        name="orderedUsers"
        :list="orderedUsers"
        :per="lim"
        class="pl-0"
      > -->
        
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
            </tr>
          </thead>
          <tbody>
            <router-link  class="link_row" :to="{ name: 'EditSeason', params: { id: us.id }}" v-for="(us,index) in seasons " :key="user+index">
            <tr class="table-det spacer">
              <td>{{us.name}}</td>
              <td>{{moment(us.start_at).format('LL')}}</td>
              <td>{{moment(us.end_at).format('LL')}}
                  <font-awesome-icon  v-on:click.prevent="remItem(us.id)" class="float-right" :icon="['fal', 'times']" />           
              </td>
            </tr>
            </router-link>
          </tbody>
        </table>
         <ul class="pl-0 paginate-links">
     <li v-if="pages>0"  @click="getSeasons(1)" class="left-arrow disabled">
       <a>«</a>
       </li>

       <li @click="getSeasons(p)" :class="p == current ? 'number active pg' : 'number pg'" v-for="(p,index) in pages " :key="pag+index" >
       <span   v-if="Math.abs(p - current) < 4 || p == pages  || p== 1" >
         
         <a :class="{current: current=== p, last: (p == pages  && Math.abs(p- current) > 4), first:(p == 1 && Math.abs(p - current) > 4)}" >{{p}}</a>
       </span>
         </li>
       <li  v-if="pages>0" @click="getSeasons(pages)" class="right-arrow disabled"><a>»</a></li>
    </ul>
      <!-- </paginate> -->
      <!-- <paginate-links class="pl-0" for="orderedUsers" :async="true" :limit="lim" :show-step-links="true"></paginate-links> -->
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'

export default {
  name: 'SeasonsPage',
  data () {
    return {    
      paginate: ['orderedUsers'],
      lim:10,
      seasons:[],
         current:1,
      pages:0,
    }
  },
  created(){
    },
  computed:{
    orderedUsers: function () {
      return this._.orderBy(this.seasons, 'id')
    }
  },
  mounted(){
  this.getSeasons(this.current)
  }, 
  components: {
  },      
  methods: {

     async getSeasons(page){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getSeasons(page);
        if(response.data.success == true){
          this.seasons = (response.data.data)  
          this.pages=   response.data.pagination.total_pages
          this.current = response.data.pagination.page         
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
      remItem(litem){
      if(confirm("Do you really want to delete?")){
        this.remove(litem)
      }
    },
    async remove(it){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.deleteSeason(it);
        if(response.data.success == true){
          this.getSeasons()
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>