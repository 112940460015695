<template>
  <div>
    <div class="table-list users-list mt-4">
      <div class="row m-0">
        <div class="col-lg-6 col-md-6 d-flex pl-0">
          <h4 class="mb-0"><b>Settings</b></h4>
          <router-link to="/admin/settings/new"  v-if="settings.affirmation==null"
            ><button class="form-btn ml-4">Add New</button></router-link
          >
        </div>
      </div>
      <br />
      <div v-if="settings.affirmation!=null">
        <h5>Affirmation</h5>
        <router-link  :to="{ name: 'EditSetting'}" >
        <div class="row m-0">
          <div class="col-lg-5 col-md-6 d-flex affblk m-0">
            {{settings.affirmation}}
          </div>
        </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";

export default {
  name: "SettingsPage",
  data() {
    return {
      settings: {},
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getSetting();
  },
  components: {},
  methods: {
      async getSetting(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.editSettings();
        if(response.data.success == true){
         this.settings=response.data.data.settings
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }
    },
  },
};
</script>