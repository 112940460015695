<template>
  <div id="new_user" class="col-lg-11 mt-4 adacc">
    <div class="col-lg-12">
    <h3><b>Edit Season</b></h3>
      <form class="" @submit.prevent="createSeason">
         <div class="mt-4">
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6">
              <label>Name</label>
              <input type="text" v-model="details.season.name"  class="input-field form-control" placeholder="" required />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-3 col-md-6">
               <label>Start Time</label>
                <datetime type="datetime" :use12-hour='u12' input-class="input-field form-control" v-model="details.season.start_at"></datetime>
            </div>
            <div class="col-lg-3 col-md-6">
               <label>End Time</label>
                <datetime type="datetime" :use12-hour='u12' input-class="input-field form-control" v-model="details.season.end_at"></datetime>
            </div>
          </div>
                                      <router-link to="/admin/seasons" ><button class="form-btn mr-4 cancel">Cancel</button></router-link>

          <button  id="saveSess" type="submit" class="form-btn ">Update Season</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'
import router from '@/router'

export default {
  name: 'EditSeasonPage',
  data () {
    return {    
      details:{
        season:{
           start_at:"",
          end_at:"",
          published:"",
          name:""
        }
      },
      id:"",
      u12:true
    }
  },
  created(){
    this.id=this.$route.params.id
  
  },
  computed:{
    
    },
  mounted(){
    this.getSeason();
  }, 
  components: {
  },      
  methods: {
      async createSeason(){
        var alert= {}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        var bt= document.getElementById('saveSess')
        const response = await AdminService.updateSeason(this.id,this.details);
        if(response.data.success == true){
         //bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          //bt.innerText = response.data.flash.notice  
           setTimeout(function(){  
             router.push({ path: '/admin/seasons' }); 
        
       
      }, 500);
        }
        else{
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Update Season" 
          }, 1500); 
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
    },
    async getSeason(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.editSeason(this.id);
        if(response.data.success == true){
         this.details.season=response.data.data.season
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>