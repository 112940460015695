<template>
  <div>
    <div class="log-form sig-form">
      <form @submit.prevent="createUser">
        <div class="form-det">
          <h2>Sign Up</h2>
                    <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 uname" >
              <input type="text" style="" class="input-field form-control right" v-model="auth.signup.firstname" placeholder="First Name" required />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0 uname" >
                <input type="text" class="input-field form-control left" v-model="auth.signup.lastname" placeholder="Last Name" style="" required />
            </div>
          </div>
          <input type="email" v-model="auth.signup.email" class="input-field form-control" placeholder="Email" required />
          <input :type="passwordFieldType" v-on:keyup="validate"  v-on:keydown="validate" v-model="auth.signup.password" class="input-field form-control" placeholder="Password" required minlength="8" />
          <span type="password" @click="switchVisibility"><i class="far fa-eye"></i></span>
          <input :type="confirmpasswordFieldType" v-on:keyup="validate"  v-on:keydown="validate" v-model="auth.signup.password_confirmation" class="input-field form-control" placeholder="Confirm Password" required minlength="8"/>
          <span type="password" @click="switchVisibilityconfirm"><i class="far fa-eye"></i></span>
          <span class="form-helper nomatch">
            Password must match the one typed before.                    
          </span>
          <button id="signBtn" type="submit" class="form-btn">Register</button>
          <p class="text-center">Already have an account? <router-link to="/login" class="link"><u><strong>Log In</strong></u></router-link></p>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import custom from '../../javascript/custom'
import AuthService from '../../services/authService'
import router from '@/router'

export default {
  name: 'SignupPage',
  data () {
    return {    
      auth:{
        signup:{
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          password_confirmation: "",
          active:true,
         canopy_number:""
        }
      },
      passwordFieldType: 'password',
      confirmpasswordFieldType: 'password'
    }
  },
  created(){
  
  },
  computed:{

  },
  mounted(){
    var urlParams = new URLSearchParams(window.location.search);
    if(window.location.href.indexOf("plan_id")>-1 && urlParams.get('plan_id')!=""){
      this.auth.signup.active = true;
      this.auth.signup.auto_plan_id = urlParams.get('plan_id')
      this.auth.signup.firstname = urlParams.get('firstname')
      this.auth.signup.lastname = urlParams.get('lastname')
      this.auth.signup.email = urlParams.get('email')
      this.auth.signup.canopy_number = urlParams.get('canopy_number')
    }
  }, 
  components: {
  },      
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      if (this.passwordFieldType == 'text'){
        custom.showPassword(0)
      }
      else{
        custom.hidePassword(0)
      }
    },
     switchVisibilityconfirm() {
      this.confirmpasswordFieldType = this.confirmpasswordFieldType === 'password' ? 'text' : 'password'
      if (this.confirmpasswordFieldType == 'text'){
        custom.showPassword(1)
      }
      else{
        custom.hidePassword(1)
      }
    },
    validate(){
      if(this.auth.signup.password != ""){
        if(this.auth.signup.password != this.auth.signup.password_confirmation){
          custom.validatePassword(false)
        }
        else{
          custom.validatePassword(true)
        }
      }
    },
    async createUser(){
      var alert= {}
      try{
        const response = await AuthService.signupUser(this.auth);  
        // var urlParams = new URLSearchParams(window.location.search);
        var bt= document.getElementById('signBtn')
        if(response.data.success == true){
          this.$bus.$emit('acuser',this.auth.signup.email);
 
         //bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          //bt.innerText = response.data.flash.notice  
           setTimeout(function(){  
                  this.$session.start()
            this.$session.set("token",(response.data.data.record.authToken))
            sessionStorage.setItem('token',response.data.data.record.authToken)
            this.$session.set("loggedIn",true)
            this.$session.set("user",response.data.data.record)
            sessionStorage.setItem('user',JSON.stringify([]))
            router.push({ path: '/customer/dashboard' });
       
          // if(window.location.href.indexOf("plan_id")>-1 && urlParams.get('plan_id')!=""){
          //   this.$session.start()
          //   this.$session.set("token",(response.data.data.record.authToken))
          //   sessionStorage.setItem('token',response.data.data.record.authToken)
          //   this.$session.set("loggedIn",true)
          //   this.$session.set("user",response.data.data.record)
          //   sessionStorage.setItem('user',JSON.stringify([]))
          //   router.push({ path: '/customer/dashboard' });
          // }
          // else{

          //   router.push({ path: '/activate' });    
          // }
      }.bind(this), 2000); 
               this.auth.signup.password="";
         
           this.auth.signup.email="";
          this.auth.signup.lastname="";
          this.auth.signup.firstname="";
          this.auth.signup.password_confirmation="";  
        }
        else if(response.data.success==false){
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Register" 
          }.bind(this), 1500); 
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
                
        }
      } catch (err){
        if (err) {
          //console.log(err)
          localStorage.clear()
          sessionStorage.clear() 
          this.$session.destroy()
      } else{
              //console.log(err)
          }
      }
        
    }
  }  
}
</script>