<template>
  <div>
     <div class="table-list users-list mt-4 mb-4">
       <div class="row">
         <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0"><b>Programs</b></h4>
          <router-link to="/admin/programs/new" ><button class="form-btn ml-4">Add New</button></router-link>
          
         </div>
       </div>
      <!-- <paginate
        name="items"
        :list="programs"
        :per="lim"
        class="pl-0"
      > -->
        
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Program</th>
              <th scope="col">Instructor</th>
              <th scope="col">Access Level</th>
              <th scope="col">Tags</th>
            </tr>
          </thead>
          <tbody>
            <router-link  class="link_row" :to="{ name: 'EditProgram', params: { id: program.id }}" v-for="(program,index) in programs" :key="program+index">
              <tr class="table-det spacer">
                <td>{{program.name}}</td>
                <td><span v-for="(ins,index) in program.instructors" :key="ins+index">{{ins.name}} </span></td>
                <td class="urltxt"><span v-for="(pl,index) in program.plans" :key="plan+index">{{pl.name}} </span></td>
                 <td class="urltxt"><span v-for="(pl,index) in program.tags" :key="tag+index">{{pl.label}} </span>
                 <font-awesome-icon  v-on:click.prevent="remItem(program.id)" class="float-right" :icon="['fal', 'times']" />                 
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>
         <ul class="pl-0 paginate-links">
     <li v-if="pages>0"  @click="getPrograms(1)" class="left-arrow disabled">
       <a>«</a>
       </li>

       <li @click="getPrograms(p)" :class="p == current ? 'number active pg' : 'number pg'" v-for="(p,index) in pages " :key="pag+index" >
       <span   v-if="Math.abs(p - current) < 4 || p == pages  || p== 1" >
         
         <a :class="{current: current=== p, last: (p == pages  && Math.abs(p- current) > 4), first:(p == 1 && Math.abs(p - current) > 4)}" >{{p}}</a>
       </span>
         </li>
       <li  v-if="pages>0" @click="getPrograms(pages)" class="right-arrow disabled"><a>»</a></li>
    </ul>
      <!-- </paginate> -->
      <!-- <paginate-links class="pl-0" for="items" :async="true" :limit="lim" :show-step-links="true"></paginate-links> -->
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'

export default {
  name: 'ProgramsPage',
  data () {
    return {    
      paginate: ['items'],
      lim:10,
      programs:[],
         current:1,
      pages:0,
    }
  },
  created(){
    },
  computed:{
    
    },
  mounted(){
    this.getPrograms(this.current)
  }, 
  components: {
  },      
  methods: {
    async getPrograms(page){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getPrograms(page);
        if(response.data.success == true){
          this.programs = (response.data.data)
          this.pages=   response.data.pagination.total_pages
          this.current = response.data.pagination.page           
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
      remItem(litem){
      if(confirm("Do you really want to delete?")){
        this.remove(litem)
      }
    },
    async remove(it){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.deleteProgram(it);
        if(response.data.success == true){
          this.getPrograms()
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>