<template>
  <div id="new_user" class="col-lg-11 mt-4 adacc">
    <div class="col-lg-12">
    <h3><b>Edit Session</b></h3>
      <form class="" @submit.prevent="createSession">
        <div class="mt-4">
          <div class="row mb-3">
          
            <div class="col-lg-3 col-md-4">
               <label>Program</label>
               <v-select class="prog" :options="programs"  @input="handleSelect()" :multiple="false" v-model="details.session.program_id" :reduce="name=>name.id " label="name"  />
            </div>
            <div class="col-lg-3 col-md-4">
               <label>Session Instructor</label>
                <v-select :options="presenters"  :multiple="false" v-model="details.session.presenter_id"  :reduce="name=>name.id " label="name"  required/>
            </div>
            <div class="col-lg-3 col-md-4">
              <label>Live/Pre-Recorded</label>
              <v-select :options="schemes"  :multiple="false" v-model="details.session.scheme"  />
            </div>
          </div>
          <div class="row mb-3">
              <div class="col-lg-6 col-md-6">
              <label>Title</label>
              <input  v-model="details.session.title" class="input-field form-control" placeholder="" required />
            </div>
            <div class="col-lg-3 col-md-6">
               <label>Start Time</label>
                <datetime type="datetime" :use12-hour='u12' zone="local" value-zone="local" input-class="input-field form-control" v-model="details.session.start_at" required></datetime>
            </div>
             <div class="col-lg-3 col-md-4">
               <label>Time Limit</label>
                <v-select :options="timeperiod"  :multiple="false" v-model="details.session.length"  :reduce="name=>name.value " label="name"/>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-3 col-md-4">
               <label>Tags</label>  
              <v-select :options="tags"  :multiple="true" v-model="details.session.tag_ids" :reduce="label=>label.id " label="label" />
            </div>
            <div class="col-lg-3 col-md-4">
               <label>Content Platform</label>
              <v-select :options="content_host_types"  :multiple="false" v-model="details.session.content_host_type"  />
            </div>
            <div class="col-lg-6 col-md-4">
               <label>Content URL</label>
              <input  v-model="details.session.content_url" class="input-field form-control" placeholder="" required />
            </div>
          </div>
           <div class="row">
             <div class="col-lg-3">
              <label>Thumbnail</label>
              <br/>
              <input type="file" @change="getThumb" ref="thumbupload"  />
            </div>
            
            <div class="col-lg-12 mt-2">
                <img  class="av" v-if="details.session.thumbnail" :src="details.session.thumbnail" />
             </div>
           
          </div>
          <br>
           <div class="row mb-4">
                    <div class="col-lg-3 col-md-12">
                         <label>Publish Session</label>
                        <input type="checkbox" v-model="details.session.published" class="ml-3" placeholder="" />
                   </div>

                </div>
                                    <router-link to="/admin/sessions" ><button class="form-btn mr-4 cancel">Cancel</button></router-link>

          <button  id="saveSess" type="submit" class="form-btn ">Update Session</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'
import router from '@/router'
import $ from 'jquery'

export default {
  name: 'EditSessionPage',
  data () {
    return {    
      details:{
        session:{
          start_at:"",
          end_at:"",
          // published:true,
          content_url: null,
          content_host_type: null,
          program_id: null,
          thumbnail:"",
          scheme:"",
          presenter_id:"",
          title:"",
          tag_ids:[],
          length:""
        }
      },
      id:"",
      u12:true,
      presenters:[],
      programs:[],
      schemes:[],
      content_host_types:[],
      tags:[],
       timeperiod:[
        {
          name:"15 Minutes",
          value:900
        },
        {
          name:"30 Minutes",
          value:1800
        },
        {
          name:"45 Minutes",
          value:2700
        },
        {
          name:"1 Hour",
          value:3600
        },
        {
          name:"1 Hour 15 Minutes",
          value:4500
        },
        {
          name:"1 Hour 30 Minutes",
          value:5400
        },
        {
          name:"1 Hour 45 Minutes",
          value:6300
        },
        {
          name:"2 Hours",
          value:7200
        }
      ]
    }
  },
  created(){
    this.id=this.$route.params.id
  
  },
 computed: {
    // foo() {
    //   return this.details.session.title === '' ? '' : this.details.session.program_id ;
    // }
  },
  watch: {
    // foo() {
    //    setTimeout(function(){  
          
    //   var pro = $('.prog').children(".vs__dropdown-toggle").children(".vs__selected-options").children(".vs__selected")
    //   this.details.session.title=$.trim($(pro).text())
    //       }.bind(this), 500); 
    
    // }
  },
  mounted(){
    this.getSession();
  }, 
  components: {
  },      
  methods: {
    handleSelect(){
        setTimeout(function(){  
          
      var pro = $('.prog').children(".vs__dropdown-toggle").children(".vs__selected-options").children(".vs__selected")
      this.details.session.title=$.trim($(pro).text())
          }.bind(this), 500); 

    },

      async createSession(){
        var alert= {}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        var bt= document.getElementById('saveSess')
         if(this.details.session.thumbnail.includes("data:image")==false){
          delete this.details.session.thumbnail
        }
        const response = await AdminService.updateSession(this.id,this.details);
        if(response.data.success == true){
         //bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          //bt.innerText = response.data.flash.notice  
           setTimeout(function(){  
             router.push({ path: '/admin/sessions' }); 
         
       
      }, 500);
        }
        else{
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Update Session" 
          }, 1500); 
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
    },
    async getSession(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.editSession(this.id);
        if(response.data.success == true){
         this.details.session=response.data.data.sesson
          this.presenters     = (response.data.data.options.presenters)
           this.programs     = (response.data.data.options.programs)
           this.content_host_types    = (response.data.data.options.content_host_types )
           this.schemes      = (response.data.data.options.schemes)
             this.tags      = (response.data.data.options.tags)
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }

       try{
        const response = await AdminService.showSession(this.id);
        if(response.data.success == true){
         this.details.session.thumbnail=response.data.data.session.thumbnail_urls.large
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }
    },
     getThumb(event){
         const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > 4) {
          alert('File size exceeds 4 MB');
          this.$refs.thumbupload.value=null
        }
        else{
      const fr = new FileReader()
      fr.onloadend = function(){
         this.details.session.thumbnail =fr.result
        }.bind(this)
        
      fr.readAsDataURL(event.target.files[0])
        }
    },
  }  
}
</script>