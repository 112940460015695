import AdminLayout from '@/views/layouts/admin'
import AccountPage from '@/views/admin/account/show'
// sessions
import SessionsPage from '@/views/admin/sessions/all'
import NewSessionsPage from '@/views/admin/sessions/new'
import EditSessionsPage from '@/views/admin/sessions/edit'
// users
import UsersPage from '@/views/admin/users/all'
import NewUsersPage from '@/views/admin/users/new'
import EditUsersPage from '@/views/admin/users/edit'
// coupons
import CouponsPage from '@/views/admin/plans/coupons/all'
import NewCouponsPage from '@/views/admin/plans/coupons/new'
import EditCouponsPage from '@/views/admin/plans/coupons/edit'
// plans
import PlansPage from '@/views/admin/plans/all'
import NewPlansPage from '@/views/admin/plans/new'
import EditPlansPage from '@/views/admin/plans/edit'
// ebooks
import EbooksPage from '@/views/admin/ebooks/all'
import NewEbooksPage from '@/views/admin/ebooks/new'
import EditEbookPage from '@/views/admin/ebooks/edit'
// roles
import RolePage from '@/views/admin/users/roles/all'
import NewRolePage from '@/views/admin/users/roles/new'
import EditRolePage from '@/views/admin/users/roles/edit'
// programs
import ProgramPage from '@/views/admin/programs/all'
import NewProgramPage from '@/views/admin/programs/new'
import EditProgramPage from '@/views/admin/programs/edit'
// seasons
import SeasonsPage from '@/views/admin/seasons/all'
import NewSeasonsPage from '@/views/admin/seasons/new'
import EditSeasonsPage from '@/views/admin/seasons/edit'
// categories
import CategoriesPage from '@/views/admin/categories/all'
import NewCategoriesPage from '@/views/admin/categories/new'
import EditCategoriesPage from '@/views/admin/categories/edit'
// tags
import TagsPage from '@/views/admin/tags/all'
import NewTagsPage from '@/views/admin/tags/new'
import EditTagsPage from '@/views/admin/tags/edit'
// tags
import TransactionsPage from '@/views/admin/transactions/all'
// import NewTagsPage from '@/views/admin/tags/new'
// import EditTagsPage from '@/views/admin/tags/edit'
import SettingsPage from '@/views/admin/settings/show'
import NewSettingsPage from '@/views/admin/settings/new'
import EditSettingsPage from '@/views/admin/settings/edit'

export default {
  path:'/admin',
  component:AdminLayout,
  redirect: '/admin/users' ,
  meta:{
    requiresAuth:true,
    is_admin : true
  },
  children: [
    {
      path: 'users',
      name: 'Users',
      meta:{layout: 'admin',title: 'Users'},
      component:UsersPage,
    },
    {
      path: 'users/new',
      name: 'NewUser',
      meta:{layout: 'admin',title: 'Users'},
      component:NewUsersPage,
    },
    {
      path: 'users/:id/edit',
      name: 'EditUser',
      meta:{layout: 'admin',title: 'Users'},
      component:EditUsersPage,
    },
    {
      path: 'roles',
      name: 'Roles',
      meta:{layout: 'admin',title: 'Role'},
      component:RolePage,
    },
    {
      path: 'roles/new',
      name: 'NewRole',
      meta:{layout: 'admin',title: 'Role'},
      component:NewRolePage,
    },
    {
      path: 'roles/:id/edit',
      name: 'EditRole',
      meta:{layout: 'admin',title: 'Role'},
      component:EditRolePage,
    },
    {
      path: 'account',
      name: 'AAccount',
      meta:{layout: 'admin',title: 'AAccount'},
      component: AccountPage,
    },
    {
      path: 'plans',
      name: 'APlans',
      meta:{layout: 'admin',title: 'Plans'},
      component:PlansPage,
    },
    {
      path: 'plans/new',
      name: 'NPlans',
      meta:{layout: 'admin',title: 'Plans'},
      component:NewPlansPage,
    },
    {
      path: 'plans/:id/edit',
      name: 'EditPlans',
      meta:{layout: 'admin',title: 'Plans'},
      component:EditPlansPage,
    },
    {
      path: 'coupons',
      name: 'Coupon',
      meta:{layout: 'admin',title: 'Coupon'},
      component:CouponsPage,
    },
    {
      path: 'coupons/new',
      name: 'NCoupon',
      meta:{layout: 'admin',title: 'Coupon'},
      component:NewCouponsPage,
    },
    {
      path: 'coupons/:id/edit',
      name: 'EditCoupon',
      meta:{layout: 'admin',title: 'Coupon'},
      component:EditCouponsPage,
    },
    {
      path: 'sessions',
      name: 'Sessions',
      meta:{layout: 'admin',title: 'Sessions'},
      component: SessionsPage,
    },
    {
      path: 'sessions/new',
      name: 'NSessions',
      meta:{layout: 'admin',title: 'Sessions'},
      component: NewSessionsPage,
    },
    {
      path: 'sessions/:id/edit',
      name: 'EditSession',
      meta:{layout: 'admin',title: 'Sessions'},
      component: EditSessionsPage,
    },
    {
      path: 'links',
      name: 'Ebooks',
      meta:{layout: 'admin',title: 'Ebooks'},
      component:EbooksPage,
    },
    {
      path: 'links/new',
      name: 'NEbooks',
      meta:{layout: 'admin',title: 'Ebooks'},
      component:NewEbooksPage,
    },
    {
      path: 'links/:id/edit',
      name: 'EditEbook',
      meta:{layout: 'admin',title: 'Ebooks'},
      component:EditEbookPage,
    },
    {
      path: 'programs',
      name: 'Programs',
      meta:{layout: 'admin',title: 'Programs'},
      component:ProgramPage,
    },
    {
      path: 'programs/new',
      name: 'NProgram',
      meta:{layout: 'admin',title: 'Programs'},
      component:NewProgramPage,
    },
    {
      path: 'programs/:id/edit',
      name: 'EditProgram',
      meta:{layout: 'admin',title: 'Programs'},
      component:EditProgramPage,
    },
    {
      path: 'seasons',
      name: 'Seasons',
      meta:{layout: 'admin',title: 'Seasons'},
      component: SeasonsPage,
    },
    {
      path: 'seasons/new',
      name: 'NSeasons',
      meta:{layout: 'admin',title: 'Seasons'},
      component: NewSeasonsPage,
    },
    {
      path: 'seasons/:id/edit',
      name: 'EditSeason',
      meta:{layout: 'admin',title: 'Seasons'},
      component: EditSeasonsPage,
    },
    {
      path: 'categories',
      name: 'Categories',
      meta:{layout: 'admin',title: 'Categories'},
      component: CategoriesPage,
    },
    {
      path: 'categories/new',
      name: 'NCategory',
      meta:{layout: 'admin',title: 'Categories'},
      component: NewCategoriesPage,
    },
    {
      path: 'categories/:id/edit',
      name: 'EditCategory',
      meta:{layout: 'admin',title: 'Categories'},
      component: EditCategoriesPage,
    },
    {
      path: 'settings/new',
      name: 'NewSettings',
      meta:{layout: 'admin',title: 'Settings'},
      component:NewSettingsPage,
    },
    {
      path: 'settings',
      name: 'Settings',
      meta:{layout: 'admin',title: 'Settings'},
      component:SettingsPage,
    },
    {
      path: 'settings/:id/edit',
      name: 'EditSetting',
      meta:{layout: 'admin',title: 'Settings'},
      component:EditSettingsPage,
    },
    {
      path: 'tags',
      name: 'Tags',
      meta:{layout: 'admin',title: 'Tags'},
      component: TagsPage,
    },
    {
      path: 'tags/new',
      name: 'NTag',
      meta:{layout: 'admin',title: 'Tags'},
      component: NewTagsPage,
    },
    {
      path: 'tags/:id/edit',
      name: 'EditTag',
      meta:{layout: 'admin',title: 'Tags'},
      component: EditTagsPage,
    },
    {
      path: 'transactions',
      name: 'Transactions',
      meta:{layout: 'admin',title: 'Transactions'},
      component: TransactionsPage,
    },
  ]
}