<template>
  <div>
     <div class="table-list users-list mt-4 mb-4">
       <div class="row">
         <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0"><b>Plans</b></h4>
          <router-link to="/admin/plans/new" ><button class="form-btn ml-4">Add New</button></router-link>
          
         </div>
          <div class="col-lg-6 col-md-6 text-right rbtn">  
            <router-link to="/admin/coupons" ><button class="form-btn">Manage Coupons</button></router-link>
         </div>
       </div>
      <!-- <paginate
        name="plans"
        :list="plans"
        :per="lim"
        class="pl-0"
      >
         -->
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Plan</th>
              <th scope="col">Price</th>
              <th scope="col">Active Subscribers</th>
              <!-- <th scope="col">Trial Subscribers</th> -->
              <th scope="col">Expired Subscribers</th>
            </tr>
          </thead>
          <tbody>
            <router-link  class="link_row" :to="{ name: 'EditPlans', params: { id: plan.id }}" v-for="(plan,index) in plans" :key="plan+index">
              <tr class="table-det spacer" >
                <td>{{plan.name}}</td>
                <td>{{plan.price}}</td>
                <td>{{plan.active_subscribers_count}}</td>
                <!-- <td>{{plan.trial_subscribers_count}}</td> -->
                <td>{{plan.expired_subscribers_count}}
                  <font-awesome-icon  v-on:click.prevent="remItem(plan.id)" class="float-right" :icon="['fal', 'times']" />
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>
         <ul class="pl-0 paginate-links">
     <li v-if="pages>0"  @click="getPlans(1)" class="left-arrow disabled">
       <a>«</a>
       </li>

       <li @click="getPlans(p)" :class="p == current ? 'number active pg' : 'number pg'" v-for="(p,index) in pages " :key="pag+index" >
       <span   v-if="Math.abs(p - current) < 4 || p == pages  || p== 1" >
         
         <a :class="{current: current=== p, last: (p == pages  && Math.abs(p- current) > 4), first:(p == 1 && Math.abs(p - current) > 4)}" >{{p}}</a>
       </span>
         </li>
       <li  v-if="pages>0" @click="getPlans(pages)" class="right-arrow disabled"><a>»</a></li>
    </ul>
      <!-- </paginate> -->
      <!-- <paginate-links class="pl-0" for="plans" :async="true" :limit="lim" :show-step-links="true"></paginate-links> -->
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'

export default {
  name: 'PlansPage',
  data () {
    return {    
      paginate: ['plans'],
      lim:10,
      plans:[],
         current:1,
      pages:0,
    }
  },
  created(){
    },
  computed:{
    
    },
  mounted(){
    this.getPlans(this.current)
  }, 
  components: {
  },      
  methods: {
     async getPlans(page){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getPlans(page);
        if(response.data.success == true){
          this.plans = (response.data.data)   
          this.pages=   response.data.pagination.total_pages
          this.current = response.data.pagination.page        
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
      remItem(litem){
      if(confirm("Do you really want to delete?")){
        this.remove(litem)
      }
    },
    async remove(it){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.deletePlan(it);
        if(response.data.success == true){
          this.getPlans()
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>