<template>
<div id="new_user" class="col-lg-11 mt-4 mb-5 pb-3 adacc">
    <h3><b>Edit Plan</b></h3>
    <div class="">
        <form @submit.prevent="updatePlan">
            <div class="mt-4">
               

                <div class="row mb-4">
                    <div class="col-lg-3 col-md-12">
                        <label>Plan Name</label>
                        <input type="text" v-model="details.plan.name" class="input-field form-control" placeholder="" required />
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <label>Price</label>
                        <input type="text" v-model="details.plan.price" class="input-field form-control" placeholder="" required />
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <label>Subscriber Limit</label>
                        <input type="text" v-model="details.plan.subscriber_limit" class="input-field form-control" placeholder="" required/>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-lg-6 col-md-6">
                        <label>Description</label>
                        <textarea rows="4" v-model="details.plan.description" class="input-field form-control mt-0" required></textarea>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <label>Currency</label>
                        <input type="text" v-model="details.plan.currency" class="input-field form-control" placeholder="" required />
                    </div>

                </div>
                <div class="row mb-4">
                    <div class="col-lg-3 col-md-6">
                        <label>Payment Period</label>
                        <input type="text" v-model="details.plan.subscription_period_amount" class="input-field form-control" placeholder="" required />
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <label>Payment Period Unit</label>
                        <input type="text" v-model="details.plan.subscription_period_unit" class="input-field form-control" placeholder="" required />
                    </div>
                     <div class="col-lg-3 col-md-6">
                        <label>Live/Pre-Recorded Access</label>
                        <v-select :options="schemes" :multiple="true" v-model="details.plan.schemes" />
                    </div>
                    <!-- <div class="col-lg-2 col-md-6">
                        <label>Grace Period</label>
                        <input type="text" v-model="details.plan.grace_period_amount" class="input-field form-control" placeholder="" />
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <label>Grace Period Unit</label>
                        <input type="text" v-model="details.plan.grace_period_unit" class="input-field form-control" placeholder="" />
                    </div> -->

                </div>
                          <div class="row mb-4">
            <div class="col-lg-2 col-md-6">
               <label>Trial Period</label>
                <input type="text" v-model="details.plan.trial_period_amount" class="input-field form-control" placeholder=""  />
            </div>
             <div class="col-lg-3 col-md-6">
               <label>Trial Period Unit</label>
                <input type="text" v-model="details.plan.trial_period_unit" class="input-field form-control" placeholder=""  />
            </div>
          
          </div>
                <div class="row mb-4">
                    <!-- <div class="col-lg-3 col-md-6">
              <label>Program Category Access</label>
                <v-select :options="categories"  :multiple="true" v-model="details.plan.program_category_ids" :reduce="name=>name.id " label="name"  />
            </div> -->
                    <!-- <div class="col-lg-3 col-md-6">
                        <label>Seasons</label>
                        <v-select :options="seasons" :multiple="true" v-model="details.plan.season_ids" :reduce="name=>name.id " label="name" />
                    </div> -->
                   
                   <div class="col-lg-5 col-md-6">
               <label>Programs Access</label>
                <v-select :options="programs"  @input="handleSelect()"   :multiple="true" v-model="details.plan.program_ids" :reduce="name=>name.id " label="name"/>
            </div>
                </div>
                 <div class="row mb-4">
                    <div class="col-lg-3 col-md-12">
                         <label>Make Plan Private</label>
                        <input type="checkbox" v-model="details.plan.is_private" class="ml-3" placeholder="" />
                   </div>

                </div>
                 <div class="row mb-4">
                    <div class="col-lg-3 col-md-12">
                         <label>Publish Plan</label>
                        <input type="checkbox" v-model="details.plan.mark_as_published" class="ml-3" placeholder="" />
                   </div>

                </div>
                 <div class="row mb-4">
                    <div class="col-lg-3 col-md-12">
                         <label>Auto Renew Plan</label>
                        <input type="checkbox" v-model="details.plan.auto_renew" class="ml-3" placeholder="" />
                   </div>

                </div>

                <router-link to="/admin/plans"><button class="form-btn mr-4 cancel">Cancel</button></router-link>

                <button id="savePl" type="submit" class="form-btn ">Update Plan</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'
import router from '@/router'

export default {
    name: 'EditPlanPage',
    data() {
        return {
            details: {
                plan: {
                    content_host_types: [],
                    name: "",
                    description: "",
                    price: 0,
                    subscriber_limit: "",
                    subscription_period_amount: 0,
                    grace_period_amount: 0,
                    currency: "",
                    grace_period_unit: "",
                    subscription_period_unit: "",
                    is_private:"",
                              trial_period_amount:0,
          trial_period_unit:"day",

                }
            },
            content_types: [],
            schemes: [],
            seasons: [],
            categories: [],
            programs: [],
            id: ""
        }
    },
    created() {
        this.id = this.$route.params.id
    },
    computed: {

    },
    mounted() {
        this.getContTypes()
        this.getPlan()
    },
    components: {},
    methods: {
         handleSelect() {
      // alert(event.id)
        if ((this.details.plan.program_ids[0]) === "") {
          // select all
          this.details.plan.program_ids=[]
          for (let item of this.programs) {
            if (parseInt(item.id)) {
              this.details.plan.program_ids.push(item.id);
              // this.$emit('handle-select', item.id);
            }
          }                
        } else {
          // this.$emit('handle-select', event.id);
        }
      },
        async getContTypes() {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get("token")
            try {
                const response = await AdminService.newPlans();
                if (response.data.success == true) {
                    this.content_types = (response.data.data.options.content_host_types)
                    this.schemes = (response.data.data.options.schemes)
                    this.categories = (response.data.data.options.program_categories)
                    this.programs = (response.data.data.options.programs)
                    this.seasons = (response.data.data.options.seasons)
                           this.programs.unshift({
        name: "Select All",
        id: ""
      })
                } else {
                    //console.log("error")
                }
            } catch (err) {
                //console.log(err)
            }
        },
        async updatePlan() {
            var alert= {}
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get("token")
            try {
                var bt = document.getElementById('savePl')
                const response = await AdminService.updatePlan(this.id, this.details);
                if (response.data.success == true) {
                    ////bt.classList.add("success");
                     alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
                    // //bt.innerText = response.data.flash.notice
                    setTimeout(function(){  
                        router.push({
                            path: '/admin/plans'
                        });
 
      }, 500); 
                } else {
                    //console.log("error")
                    bt.classList.add("fail");
                    bt.innerText = response.data.flash.error
                    setTimeout(function () {
                        bt.classList.remove("fail");
                        bt.innerText = "Update Plan"
                    }, 1500);
                     alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
                }
            } catch (err) {
                //console.log(err)
            }
        },
        async getPlan() {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get("token")
            try {
                const response = await AdminService.editPlan(this.id);
                if (response.data.success == true) {
                    this.details.plan = response.data.data.plan
                } else {
                    //console.log("error")

                }
            } catch (err) {
                //console.log(err)
            }
        },

    }
}
</script>
