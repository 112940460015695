import mainInstance from '@/services/api'

export default {
  getCategories(data){
    try{
      return mainInstance().get('admin/program-categories?page='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  newCategories(){
    try{
      return mainInstance().get('admin/program-categories/new');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showCategory(data){
    try{
      return mainInstance().get('admin/program-categories/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  deleteCategory(data){
    try{
      return mainInstance().delete('admin/program-categories/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  createCategory(data){
    try{
      return mainInstance().post('admin/program-categories',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  editCategory(data){
    try{
      return mainInstance().get('admin/program-categories/'+data+'/edit');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateCategory(id,data){
    try{
      return mainInstance().patch('admin/program-categories/'+id,data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getPrograms(data){
    try{
      return mainInstance().get('admin/programs?page='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  newPrograms(){
    try{
      return mainInstance().get('admin/programs/new');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showProgram(data){
    try{
      return mainInstance().get('admin/programs/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  deleteProgram(data){
    try{
      return mainInstance().delete('admin/programs/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  createProgram(data){
    try{
      return mainInstance().post('admin/programs',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  editProgram(data){
    try{
      return mainInstance().get('admin/programs/'+data+'/edit');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateProgram(id,data){
    try{
      return mainInstance().patch('admin/programs/'+id,data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getSeasons(data){
    try{
      return mainInstance().get('admin/seasons?page='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showSeason(data){
    try{
      return mainInstance().get('admin/seasons/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  deleteSeason(data){
    try{
      return mainInstance().delete('admin/seasons/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  createSeason(data){
    try{
      return mainInstance().post('admin/seasons',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  editSeason(data){
    try{
      return mainInstance().get('admin/seasons/'+data+'/edit');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateSeason(id,data){
    try{
      return mainInstance().patch('admin/seasons/'+id,data);
    }catch(err){
      //console.log(err)
      return err
    }
  },

  getSessions(data){
    try{
      return mainInstance().get('admin/sessions?page='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  newSession(){
    try{
      return mainInstance().get('admin/sessions/new');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showSession(data){
    try{
      return mainInstance().get('admin/sessions/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  deleteSession(data){
    try{
      return mainInstance().delete('admin/sessions/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  createSession(data){
    try{
      return mainInstance().post('admin/sessions',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  editSession(data){
    try{
      return mainInstance().get('admin/sessions/'+data+'/edit');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateSession(id,data){
    try{
      return mainInstance().patch('admin/sessions/'+id,data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getPlans(data){
    try{
      return mainInstance().get('admin/plans?page='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  newPlans(){
    try{
      return mainInstance().get('admin/plans/new');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showPlan(data){
    try{
      return mainInstance().get('admin/plans/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  deletePlan(data){
    try{
      return mainInstance().delete('admin/plans/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  createPlan(data){
    try{
      return mainInstance().post('admin/plans',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  editPlan(data){
    try{
      return mainInstance().get('admin/plans/'+data+'/edit');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updatePlan(id,data){
    try{
      return mainInstance().patch('admin/plans/'+id,data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getRoles(data){
    try{
      return mainInstance().get('admin/roles?page='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showRole(data){
    try{
      return mainInstance().get('admin/roles/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  deleteRole(data){
    try{
      return mainInstance().delete('admin/roles/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  createRole(data){
    try{
      return mainInstance().post('admin/roles',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  editRole(data){
    try{
      return mainInstance().get('admin/roles/'+data+'/edit');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateRole(id,data){
    try{
      return mainInstance().patch('admin/roles/'+id,data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getUsers(data){
    try{
      return mainInstance().get('admin/users?page='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  newUser(){
    try{
      return mainInstance().get('admin/users/new');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getFilUsers(data,filter){
    try{
      return mainInstance().get('admin/users?page='+data+filter);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showUser(data){
    try{
      return mainInstance().get('admin/users/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  deleteUser(data){
    try{
      return mainInstance().delete('admin/users/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  createUser(data){
    try{
      return mainInstance().post('admin/users',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  editUser(data){
    try{
      return mainInstance().get('admin/users/'+data+'/edit');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateUser(id,data){
    try{
      return mainInstance().patch('admin/users/'+id,data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getUserCSV(data){
    try{
      return mainInstance().get('admin/users.csv?'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  newCoupons(){
    try{
      return mainInstance().get('admin/coupons/new');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getCoupons(data){
    try{
      return mainInstance().get('admin/coupons?page='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showCoupon(data){
    try{
      return mainInstance().get('admin/coupons/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  deleteCoupon(data){
    try{
      return mainInstance().delete('admin/coupons/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  createCoupon(data){
    try{
      return mainInstance().post('admin/coupons',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  editCoupon(data){
    try{
      return mainInstance().get('admin/coupons/'+data+'/edit');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateCoupon(id,data){
    try{
      return mainInstance().patch('admin/coupons/'+id,data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getEbooks(data){
    try{
      return mainInstance().get('admin/ebooks?page='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  newEbook(){
    try{
      return mainInstance().get('admin/ebooks/new');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showEbook(data){
    try{
      return mainInstance().get('admin/ebooks/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  deleteEbook(data){
    try{
      return mainInstance().delete('admin/ebooks/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  createEbook(data){
    try{
      return mainInstance().post('admin/ebooks',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  editEbook(data){
    try{
      return mainInstance().get('admin/ebooks/'+data+'/edit');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateEbook(id,data){
    try{
      return mainInstance().patch('admin/ebooks/'+id,data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getTags(data){
    try{
      return mainInstance().get('admin/tags?page='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showTag(data){
    try{
      return mainInstance().get('admin/tags/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  deleteTag(data){
    try{
      return mainInstance().delete('admin/tags/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  createTag(data){
    try{
      return mainInstance().post('admin/tags',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  editTag(data){
    try{
      return mainInstance().get('admin/tags/'+data+'/edit');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateTag(id,data){
    try{
      return mainInstance().patch('admin/tags/'+id,data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getTransactions(from,to,data){
    try{
      return mainInstance().get('admin/payments?from='+from+'&to='+to+'&page='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showTransaction(data){
    try{
      return mainInstance().get('admin/payments/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  createSettings(data){
    try{
      return mainInstance().put('admin/settings',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  editSettings(){
    try{
      return mainInstance().get('admin/settings');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateSettings(data){
    try{
      return mainInstance().put('admin/settings',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
}