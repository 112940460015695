<template>
  <div>
    <div class="offset-md-3">
      <div class="subscription-plan">
        <div v-if="hasplan==true">
          <div class="col-lg-12 p-0 mb-2">
            <div class="row">
              <div class="col-lg-5">
                <h3 class="mb-0">Current Plan</h3>
              </div>
              <div class="col-lg-7 text-right">
                <p  v-if="plan.active" class="mb-0"> Renews: {{moment(plan.expire_at).format('LL')}}</p>
                  <p  v-else class="mb-0"> Expires: {{moment(plan.expire_at).format('LL')}}</p>
              </div>
            </div>
          </div>
          <div class="plan col-lg-12">
            <div class="row">
              <div class="col-lg-6 ">
                <h3 class="mb-0 plnam">{{plan.plan_name}}</h3>
                <h4>${{plan.price}}<span>/ <span v-if="plan.subscription_period_amount>1">{{plan.subscription_period_amount}}</span> {{plan.subscription_period_unit}}<span v-if="plan.subscription_period_amount>1">s</span></span></h4>
              </div>
              <div class="col-lg-6">
                <p>{{plan.plan_description}}</p>
              </div>
            </div>
          </div>
          <!-- <div class="mt-5 mb-5">
            <h4 class="renew d-inline">Auto Renew</h4>
            <label class="switch ml-3">
              <input type="checkbox" v-model="plan.auto_renew" @change="renewPlan()">
              <span class="slider round"></span>
            </label>
          </div> -->
                  <div v-if="plan && plan.price>0" class="subb">
          <div class="row ml-0">
              <button type="button" @click="showPay()" class="form-btn">Manage Billing</button>
          </div>
        </div>
        </div>
        <div v-else class="mb-5">
          <h2><b>Choose A Plan</b></h2>
          <router-link to="/customer/plans">
            <button class="vplans mt-2">view plans</button>
          </router-link>
        </div>

        <div class="sub-actions">
          <div class="row " v-if="plan.plan_id ==51 || plan.plan_id ==53" >
            
            <!-- <div class="col-lg-4 col-md-4 col-sm-4"><p class="rplan repl" @click="!plan.auto_renew ? setPayment() : ''">Renew Plan</p></div> -->
            <!-- <div class="col-lg-4 col-md-4 col-sm-4"><p class="cplan"><router-link to="/customer/plans">Change Plan</router-link></p></div> -->
            <div class="col-lg-4 col-md-4 col-sm-4"><p :class="(plan && plan.active != false && plan != null )? 'xplan'  : 'xplan rplan'" @click="plan ? cancel=true:cancel=false">Cancel Plan</p></div>
          </div>
        </div>
        <div  class="mt-5" v-if=" cancel==true && (plan && plan.active != false && plan != null )">
          <h3 class="text-center">Are you sure you want to cancel this plan?</h3>
          <div class="row mt-3">
            <div v-if="plan.plan_id ==51 || plan.plan_id ==53" class="col-lg-6 col-md-6 col-sm-6"><button class="cbtn-y" type="button" data-toggle="modal" data-target="#wixModal">Yes</button></div>
            <div v-else class="col-lg-6 col-md-6 col-sm-6"><button class="cbtn-y" @click="removeSub(plan.plan_id)">Yes</button></div>
            <div class="col-lg-6 col-md-6 col-sm-6"><button class="cbtn-n" @click="cancel=false">No</button></div>
          </div>
        </div>
      </div>
    </div>

<!-- Modal -->
<div class="modal fade" id="wixModal" tabindex="-1" aria-labelledby="wixModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Cancel Subscription</h5>
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        <i class="fas fa-times btn-close" data-dismiss="modal" aria-label="Close"></i>
      </div>
      <div class="modal-body">
       To cancel your WellFit membership, please contact our customer service team at <a href="mailto:LiveWell@TheWellFitClub.com">LiveWell@TheWellFitClub.com</a> for assistance.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

  </div>
</template>

<script>
import custom from '../../../javascript/custom'
import router from '@/router'
import UserService from '../../../services/userService'
import axios from 'axios'

export default {
  name: 'SubscriptionsPage',
  data () {
    return {    
      plan:{},
      cancel:false,
      autorenew:true,
      hasplan:false,
      merch:''
    }
  },
  created(){
   this.id= this.$session.get("user").id

  },
  computed:{

  },
  mounted(){
    // this.$bus.$on('plan', (data) => {
    //   this.$session.set('plan',data)
    //   this.plan=this.$session.get('plan')
    //   window.location.replace("https://clam.wellfit.c66.me/plans/"+this.plan.id+"/subscribe?token="+this.$session.get("ptok"));
    // })
    // this.$bus.$on('planc', (data) => {
    //   this.$session.set('plan',data)
    //   this.plan=this.$session.get('plan')
    //   window.location.replace("https://clam.wellfit.c66.me/plans/"+this.plan.id+"/subscribe?token="+this.$session.get("ptok")+"&coupon="+this.$session.get("coup"));
    // })
      // this.url="https://clam.wellfit.c66.me/plans/"+this.plan.id+"/subscribe?token="+this.$session.get("ptok")
 
    // this.plan=this.$session.get('plan')
    this.getProfile()
  }, 
  components: {
  },      
  methods: {
    async showPay(){
      // var alert={}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await UserService.getStripe();
        if(response.data.success==true){
        console.log("Stripe")
        var st = response.data.data.stripe_session.stripe_session.url
        window.open(st,"_self")
        }
        else{
          console.log("error")
            //    alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            // this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
    },
    async renewPlan(){
      var alert={}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await UserService.updateSubAuto(this.plan);
        if(response.data.success == true){
          this.getProfile()
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
         
          // this.plan = (response.data.data.profile.current_subscription)    
          // this.user.profile.avatar = response.data.data.profile.avatar.large
        }
        else{
          //console.log("error")
               alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
      
    },
    setPayment(){
      this.$bus.$emit('renew', true);
      router.push({ path: '/customer/account/payment' });
    },
      async getProfile(){
        // var alert={}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await UserService.getProfile(this.id);
        if(response.data.success == true){
          this.plan = (response.data.data.profile.current_subscription)  
          this.merch = response.data.data.profile.merchant_id 
            // alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            // this.$bus.$emit('flash',alert); 
          if(this.plan==null){
            this.hasplan=false
            this.$session.set("subscription",false)
          }
          else{
            this.hasplan=true
            this.$session.set("subscription",true)
            if(this.plan.auto_renew==false){
            custom.norenew()
            }
            else{
              custom.autorenew()
            }
          } 
          // this.user.profile.avatar = response.data.data.profile.avatar.large
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
      
    },
       async removeSub(bid){
         var alert={}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await UserService.removeSubscription(bid);
        if(response.data.success == true){
          // this.books = (response.data.data)  
             this.getProfile() 
              alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
               setTimeout(function(){  
                 router.push({ path: '/customer/dashboard' });
          }, 2000);
           
        }
        else{
          //console.log("error")
               alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>