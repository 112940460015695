import SessionLayout from '@/views/layouts/session'

import LoginPage from '@/views/sessions/login'
import SignupPage from '@/views/sessions/signup'
import ForgotPage from '@/views/sessions/forgot-password'
import ResetPage from '@/views/sessions/reset-password'
import ActivationPage from '@/views/sessions/activation'
import AdminSignupPage from '@/views/sessions/super_user'
export default {
  path: '/',  
  component:SessionLayout,
  meta:{requiresAuth:false},
  children: [
    {
      path: '/',
      name: 'Login',
      meta:{layout: 'session',title: 'Login'},
      component: LoginPage,
    },
    {
      path: 'login',
      name: 'LoginPage',
      meta:{layout: 'session',title: 'Login'},
      component: LoginPage,
    },
    {
      path: 'signup',
      name: 'Signup',
      meta:{layout: 'session',title: 'Signup'},
      component: SignupPage,
    },
    {
      path: 'admin/signup',
      name: 'Admin Signup',
      meta:{layout: 'session',title: 'Signup'},
      component: AdminSignupPage,
    },
    {
      path: 'forgot-password',
      name: 'Forgot',
      meta:{layout: 'session',title: 'Forgot Password'},
      component: ForgotPage,
    },
    {
      path: 'reset-password',
      name: 'Resett',
      meta:{layout: 'session',title: 'Reset Password'},
      component: ResetPage,
      props: true
    },
    {
      path: 'activate',
      name: 'Activate',
      meta:{layout: 'session',title: 'Activate'},
      component: ActivationPage,
    }
  ]
}
