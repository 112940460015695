<template>
  <div>
     <div class="table-list users-list mt-4 mb-4">
       <div class="row">
         <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0"><b>Links</b></h4>
          <router-link to="/admin/links/new" ><button class="form-btn ml-4">Add New</button></router-link>    
         </div>
       </div>
      <!-- <paginate
        name="items"
        :list="books"
        :per="lim"
        class="pl-0"
      > -->
        
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Author</th>
              <th scope="col">Tags</th>
              <th scope="col">Content URL</th>
                  <!-- <th scope="col">File URL</th> -->
            </tr>
          </thead>
          <tbody>
            <router-link  class="link_row" :to="{ name: 'EditEbook', params: { id: book.id }}" v-for="(book,index) in books" :key="book+index">
            <tr class="table-det spacer" >
              <td>{{book.title}}</td>
              <td>{{book.author}}</td>
              <td><span class="ml-2" v-for="(pl,index) in book.tags" :key="plan+index">{{pl.label}}</span></td>
              <!-- <td class="urltxt">{{book.content_url}}
              </td> -->
               <td class="urltxt">
                 <span v-if="book.content_type=='file upload'">{{book.download_url}}</span>
                  <span v-else>{{book.content_url}}</span>
                <font-awesome-icon  v-on:click.prevent="remItem(book.id)" class="float-right" :icon="['fal', 'times']" />
              </td>
            </tr>
            </router-link>
          </tbody>
        </table>
         <ul class="pl-0 paginate-links">
     <li v-if="pages>0"  @click="getBooks(1)" class="left-arrow disabled">
       <a>«</a>
       </li>

       <li @click="getBooks(p)" :class="p == current ? 'number active pg' : 'number pg'" v-for="(p,index) in pages " :key="pag+index" >
       <span   v-if="Math.abs(p - current) < 4 || p == pages  || p== 1" >
         
         <a :class="{current: current=== p, last: (p == pages  && Math.abs(p- current) > 4), first:(p == 1 && Math.abs(p - current) > 4)}" >{{p}}</a>
       </span>
         </li>
       <li  v-if="pages>0" @click="getBooks(pages)" class="right-arrow disabled"><a>»</a></li>
    </ul>
      <!-- </paginate> -->
      <!-- <paginate-links class="pl-0" for="items" :async="true" :limit="lim" :show-step-links="true"></paginate-links> -->
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'

export default {
  name: 'EbooksPage',
  data () {
    return {    
      paginate: ['items'],
      lim:10,
      books:[],
         current:1,
      pages:0,
    }
  },
  created(){
    
    },
  computed:{
    
    },
  mounted(){
    this.getBooks(this.current)
  }, 
  components: {
  },      
  methods: {
    async getBooks(page){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getEbooks(page);
        if(response.data.success == true){
          this.books = (response.data.data)
          this.pages=   response.data.pagination.total_pages
          this.current = response.data.pagination.page           
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
    remItem(litem){
      if(confirm("Do you really want to delete?")){
        this.remove(litem)
      }
    },
    async remove(it){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.deleteEbook(it);
        if(response.data.success == true){
          this.getEbooks()
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>