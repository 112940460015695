<template>
  <div class="offset-md-3 ">
    <div class="profile-form">
      <form @submit.prevent="updateProfile">
        <div class="">

          <div class="row  mb-3">
            <div class="col-lg-12">
              <label><b>Upload Profile Photo</b></label>
              <br/>
              <input type="file" @change="getPhoto"  ref="thumbupload"/>
            </div>
              <div class="col-lg-12 mt-2">
                <img  class="av" v-if="user.profile.avatar" :src="user.profile.avatar" />
             </div>
            
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <input type="text" class="input-field form-control" v-model="user.profile.firstname" placeholder="First Name" required />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <input type="text" class="input-field form-control" v-model="user.profile.lastname" placeholder="Last Name" required />
            </div>
          </div>
          <!-- <input type="tel" class="input-field form-control" placeholder="Phone Number" required /> -->
          <input type="email" class="input-field form-control"  v-model="user.profile.email" placeholder="Email Address" readonly/>
          <!-- <input :type="passwordFieldType" v-on:keyup="validate"  v-on:keydown="validate" v-model="user.profile.old_password" class="input-field form-control" placeholder="Old Password" required />
          <span type="password" @click="switchVisibility"><i class="far fa-eye"></i></span> -->
          <input :type="passwordFieldType" v-on:keyup="validate"  v-on:keydown="validate" v-model="user.profile.password" class="input-field form-control" placeholder="New Password" minlength="8" />
          <span type="password" @click="switchVisibility"><i class="far fa-eye"></i></span>
          <input :type="confirmpasswordFieldType" v-on:keyup="validate"  v-on:keydown="validate" v-model="user.profile.password_confirmation" class="input-field form-control" placeholder="Confirm New Password" minlength="8"/>
          <span type="password" @click="switchVisibilityconfirm"><i class="far fa-eye"></i></span>
          <span class="form-helper nomatch">
            Password must match the one typed before.                    
          </span>
          <button type="submit"  id="profBtn" class="form-btn col-lg-8 offset-md-2">Update Profile</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import custom from '../../../javascript/custom'
import UserService from '../../../services/userService'
import axios from 'axios'

export default {
  name: 'ProfilePage',
  data () {
    return {    
      user:{
        profile: {
          password:"",
          email:"",
          avatar:"",
          name:"",
          password_confirmation:"",
          old_password:"",
          firstname:"",
          lastname:""

      },
      },
      size:3,
      confirm_password:"",
      passwordFieldType: 'password',
      confirmpasswordFieldType: 'password',
      email:""
    }
  },
  created(){
  //  this.email= this.$session.get("user").email
      this.id= this.$session.get("user").id

  this.getProfile()
  },
  computed:{

  },
  mounted(){
    // this.user.profile = this.$session.get("user")
  }, 
  components: {
  },      
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      if (this.passwordFieldType == 'text'){
        custom.showPassword(0)
      }
      else{
        custom.hidePassword(0)
      }
    },
     switchVisibilityconfirm() {
      this.confirmpasswordFieldType = this.confirmpasswordFieldType === 'password' ? 'text' : 'password'
      if (this.confirmpasswordFieldType == 'text'){
        custom.showPassword(1)
      }
      else{
        custom.hidePassword(1)
      }
    },
    validate(){
      if(this.user.profile.password != ""){
        if(this.user.profile.password != this.user.profile.password_confirmation){
          custom.validatePassword(false)
        }
        else{
          custom.validatePassword(true)
        }
      }
    },
    getPhoto(event){
        const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > 4) {
          alert('File size exceeds 4 MB');
          this.$refs.thumbupload.value=null
        }
        else{
      const fr = new FileReader()
      fr.onloadend = function(){
         this.user.profile.avatar =fr.result
        }.bind(this)
        
      fr.readAsDataURL(event.target.files[0])
        }
    },
      async getProfile(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await UserService.getProfile(this.id);
        if(response.data.success == true){
          this.user.profile = (response.data.data.profile)    
          this.user.profile.avatar = response.data.data.profile.avatar.large
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
      
    },
     async updateProfile(){
       var alert= {}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
      var bt= document.getElementById('profBtn')
       try{
          if(this.user.profile.avatar.includes("data:image")==false){
          delete this.user.profile.avatar
        }
        const response = await UserService.updateProfile(this.user);
        if(response.data.success == true){
          // this.getProfile()
           this.user.profile = (response.data.data.profile)    
          this.user.profile.avatar = response.data.data.profile.avatar.large
             this.$bus.$emit('cusup');
             this.$session.set("user",response.data.data.profile) 
          bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          bt.innerText = response.data.flash.notice
           setTimeout(function(){  
            bt.classList.remove("success");
            bt.innerText = "Update Profile" 
            
          }, 500);   
          this.user.profile.password=""
            this.user.profile.password_confirmation=""
          
          
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>