<template>
  <div id="new_user" class="col-lg-12 ">
    <div class="col-lg-12">
    <h3><b>New Super Admin</b></h3>
      <form class="text-left" @submit.prevent="createUser">
        <div class="mt-4">
          <!-- <div class="row mb-3">
            <div class="col-lg-6 col-md-6">
              <label>Role</label>
             <v-select :options="roles"  :multiple="true" v-model="details.user.role_ids" :reduce="name=>name.id " label="label" />
            </div>
          </div> -->

          <div class="row  mb-3">
            <div class="col-lg-12">
              <label>Avatar</label>
              <br/>
              <input type="file" @change="getThumb" ref="thumbupload" />
            </div>
             <div class="col-lg-12 mt-2">
                <img  class="av" v-if="details.user.avatar" :src="details.user.avatar" />
             </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-3 col-md-6">
              <label>First Name *</label>
              <input type="text" v-model="details.user.firstname"  class="input-field form-control" placeholder="" required />
            </div>
            <div class="col-lg-3 col-md-6">
               <label>Last Name *</label>
                <input type="text" v-model="details.user.lastname"  class="input-field form-control" placeholder="" required />
            </div>
            <div class="col-lg-6">
               <label>Email Address *</label>
              <input type="email" v-model="details.user.email" class="input-field form-control" placeholder="" required />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6">
               <label>New Password*</label>
             <input :type="passwordFieldType" v-on:keyup="validate"  v-on:keydown="validate" v-model="details.user.password" class="input-field form-control" placeholder="" required minlength="8"/>
              <span type="password" @click="switchVisibility"><i class="far fa-eye"></i></span>

            </div>
            <div class="col-lg-6 col-md-6">
               <label>Confirm New Password*</label>
              <input :type="confirmpasswordFieldType" v-on:keyup="validate"  v-on:keydown="validate" v-model="details.user.password_confirmation" class="input-field form-control" placeholder=""  required minlength="8"/>
              <span type="password" @click="switchVisibilityconfirm"><i class="far fa-eye"></i></span>

            </div>
            <span class="col-lg-12 mb-3 form-helper nomatch">
              Password must match the one typed before.                    
            </span>
          </div>
           <!-- <div class="row">
            <div class="col-lg-6 col-md-12">
               <label>Date of Birth</label>
            <vue-dob-picker  select-class="dob_select" class="input-field form-control mt-0" :placeholders="['Day', 'Month', 'Year']" v-model="details.user.date_of_birth" show-labels="false" month-format="long"></vue-dob-picker>
            </div>
           
          </div> -->
     
        
          <button  id="saveUser" type="submit" class="form-btn ">Create User</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import custom from '../../javascript/custom'
import AdminService from '../../services/adminService'
import axios from 'axios'
import router from '@/router'

export default {
  name: 'NewUserPage',
  data () {
    return {    
      details:{
        user:{
          password:"",
          email:"",
          role_ids:[],
          firstname:"",
          lastname:"",
          date_of_birth:"",
          active:true,
          password_confirmation:"",
          avatar:""
        }
      },
      req:true,
      confirm_password:"",
      passwordFieldType: 'password',
      confirmpasswordFieldType: 'password',
      roles:""
    }
  },
  created(){
    
    },
  computed:{
    
    },
  mounted(){
    this.getRoles()
  }, 
  components: {
  },      
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      if (this.passwordFieldType == 'text'){
        custom.showPassword(0)
      }
      else{
        custom.hidePassword(0)
      }
    },
     switchVisibilityconfirm() {
      this.confirmpasswordFieldType = this.confirmpasswordFieldType === 'password' ? 'text' : 'password'
      if (this.confirmpasswordFieldType == 'text'){
        custom.showPassword(1)
      }
      else{
        custom.hidePassword(1)
      }
    },
    validate(){
      if(this.details.user.password != ""){
        if(this.details.user.password != this.details.user.password_confirmation){
          custom.validatePassword(false)
        }
        else{
          custom.validatePassword(true)
        }
      }
    },
      async getRoles(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getRoles();
        if(response.data.success == true){
         this.roles=response.data.data
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }
    },
      async createUser(){
        var alert= {}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        var bt= document.getElementById('saveUser')
        const response = await AdminService.createUser(this.details);
        if(response.data.success == true){
         //bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          //bt.innerText = response.data.flash.notice  
           setTimeout(function(){  
 
       
          router.push({ path: '/activate' }); 
      }, 500);
        }
        else{
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Save User" 
          }, 1500); 
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
    },
     getThumb(event){
         const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > 4) {
          alert('File size exceeds 4 MB');
          this.$refs.thumbupload.value=null
        }
        else{
      const fr = new FileReader()
      fr.onloadend = function(){
         this.details.user.avatar =fr.result
        }.bind(this)
        
      fr.readAsDataURL(event.target.files[0])
        }
    },
  }  
}
</script>