import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import moment from 'moment-timezone'
import axios from 'axios'
import VueSession from 'vue-session'
import MainLayout from '@/views/layouts/main.vue'
import SessionLayout from '@/views/layouts/session.vue'
import VueTheMask from 'vue-the-mask'
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import 'bootstrap'
import VuePaginate from 'vue-paginate'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueDOB from 'vue-dob-picker'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import AddToCalendar from 'vue-add-to-calendar'
// import { ZoomMtg } from '@zoomus/websdk';
import JsonCSV from 'vue-json-csv'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

Vue.filter('numFormat', numFormat(numeral));
Vue.component('downloadCsv', JsonCSV)
Vue.component('v-select', vSelect)
Vue.use(VuePaginate)
Vue.config.productionTip = false
library.add(fal)
Vue.use(VueTheMask)
Vue.component('mainl-layout', MainLayout);
Vue.component('session-layout', SessionLayout);
var options = {
  persist: true
}

Vue.use(VueSession, options)
Vue.use(BootstrapVue)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.component('vue-dob-picker', VueDOB)
Vue.component('datetime', Datetime);

moment.suppressDeprecationWarnings = true;

Vue.use(moment)


// var AddToCalendar = require('vue-add-to-calendar');
 
Vue.use(AddToCalendar);
// Vue.use(ZoomMtg)

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareJssdk

export const EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $bus: {
     get() {
      return EventBus;
    },
  },
});

Vue.prototype.$http = axios

Vue.prototype.moment = moment


require('./stylesheets/theme/main.scss')

new Vue({
  router,
  store:store,
  render: h => h(App),
  components: { App },
  methods:{
  }
}).$mount('#app')

Vue.config.silent = true;
