import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
// Client
import ClientRoutesM from '@/router/client-mainLayout'
import ClientRoutesS from '@/router/client-sessionLayout'
import ClientRoutesD from '@/router/client-DifLayout'
//Admin
import AdminRoutesM from '@/router/admin-mainLayout'
import NotFoundComponent from '@/views/404'

Vue.use(Router)

let router = new Router({
  mode: 'history', // to remove the hash
  duplicateNavigationPolicy: 'ignore',
  routes: [
    ClientRoutesM,
    ClientRoutesS,
    AdminRoutesM,
    ClientRoutesD,
    { path: '*', component: NotFoundComponent }
  ]
})




router.beforeEach((to, from, next) => {
  
  axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    // console.log("error")
    if (error.response.data.error.statusCode === 401) {
      next({
        name:'Login'
      })
    }
    if ( sessionStorage.getItem("vue-session-key")=="{}" && error.response.data.error.statusCode === 403) {
      next({
        name:'Login'
     })
    } 
    return Promise.reject(error)
  })
  
  var user= JSON.parse(sessionStorage.getItem('user'))
  var vu=JSON.parse(localStorage.getItem('vue-session-key'))

  if(vu && vu.user){

    vu = vu.user.role_symbols
  }
 

  if(to.matched.some(record => record.meta.requiresAuth) || from.matched.some(record => record.meta.requiresAuth)) {
    
    // alert("hi") 
    if (localStorage.getItem("vue-session-key") =='{}') {
      window.location.href = '/';
    }
    else if(localStorage.getItem("vue-session-key") ){
      // if(localStorage.getItem("vue-session-key")=="{}"){
      //   window.location.href = '/';
      // } 
      // else{
        
        if(to.matched.some(record => record.meta.is_admin)) {
         
          if(user.length==0){
            next({ name: 'Dashboardm'})  
          }
          else{
            for(var w=0;w<user.length;w++){
              if(user[w]=='admin' || user[w]=='instructor'){
                next() 
              }
              
            }
          }
        }else {
          
         if ( (vu==undefined || vu==null) && (user==undefined || user==null)){
        
          window.location.href = '/';
          }
          else{
            if(user && user.length==0 && localStorage.getItem("vue-session-key")!="{}"){
              next()  
            }
            else if(vu && vu.length==0 && localStorage.getItem("vue-session-key")!="{}"){
              next()  
            }
            else{
              for(var q=0;q<user.length;q++){
                if(user[q]=='admin' || user[q]=='instructor'){
                  next({ name: 'Users'}) 
                }
                else{
                  next()  
                }
              }
              for(var qp=0;qp<vu.length;qp++){
                if(vu[qp]=='admin' || vu[qp]=='instructor'){
                  next({ name: 'Users'}) 
                }
                else{
                  next()  
                }
              }
            }
          }
        // }
      }

     
    
    }
    else{
      window.location.href = '/';
    }
    
  }
  else{
    next()
  }
})

export default router