<template>
  <div id="saved">
    <div class="row mt-4">
      <div class="col-lg-8">
        <h2>My Saved</h2>
        <p class="mb-0" v-if="user.firstname && user.lastname">
          - saved by {{ user.firstname }} {{ user.lastname }}
        </p>
        <p class="mb-0" v-else>- saved by {{ user.name }}</p>
      </div>
    </div>
    <div class="mt-3">
      <input
        v-model="keywords"
        type="text"
        placeholder="Search Saved"
        class="search"
      />
    </div>
    <div>
      <div class="mt-4" v-if="ebooks.length == 0 && sessions.length == 0">
        <h3 class="text-center p-5">You have no saved items</h3>
      </div>
      <div v-else>
        <div v-if="ebooks.length > 0" class="ebooks mt-4">
           <div class="col-sm-12 p-0">
            <div class="row m-0">
          <h5>Downloads & Links</h5>
          <button
              :disabled="ebooks.length > 0 && sT == 0"
              class="btn-link btn prev"
              @click="prevBook()"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button
              :disabled="ebooks.length > 0 && ebooks.length <= sT+1"
              class="btn-link btn"
              @click="nextBook()"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
            </div>
            </div>
          <div class="row m-0">
            <a
              target="_blank"
              :href="book.content_url"
              class="col-lg-4 col-md-6 eblk"
              v-for="(book, index) in ebooks.slice(sT)"
              :key="'book' + index"
            >
              <div class="">
                <div class="row arow m-0">
                  <img  :src="book.thumbnail_urls ?  book.thumbnail_urls.large : '' "  />
                  <h4 class="mb-0 ml-3">
                    {{ book.title }}
                    <p>{{ book.author }}</p>
                  </h4>
                  <font-awesome-icon
                    v-if="book.bookmarked == false"
                    v-on:click.prevent="addBook(book.id)"
                    :icon="['far', 'bookmark']"
                  />
                  <font-awesome-icon
                    v-else
                    v-on:click.prevent="removeBook(book.id)"
                    :icon="['fas', 'bookmark']"
                  />
                </div>
                <p>{{ book.description }}</p>
              </div>
            </a>
          </div>
        </div>
        <div v-if="sessions.length > 0" class="mt-4 savvid">
          <br />
          <div class="col-sm-12 p-0">
            <div class="row m-0">
              <h5>Videos</h5>
              <button
                :disabled="sessions.length > 0 && sR == 0"
                class="btn-link btn prev"
                @click="prevVid()"
              >
                <i class="fas fa-chevron-left"></i>
              </button>
              <button
                :disabled="sessions.length > 0 && sessions.length <= sR+1"
                class="btn-link btn"
                @click="nextVid()"
              >
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div class="row m-0">
            <div
              v-for="(ins, index) in sessions.slice(sR)"
              :key="'ht' + index"
              class="htod col-lg-4 col-md-6 pl-0"
            >
              <router-link
                v-if="
                  ins.content_host_type == 'VimeoVideo' ||
                  ins.content_host_type == 'WellFitVideo'
                "
                :to="{ name: 'ShowEpisode', params: { id: ins.id } }"
              >
                <div class="">
                  <img class="w-100" :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
                  <p class="d-inline-block w-95">
                    {{ ins.title }}
                    {{
                        moment(ins.start_at)
                          .tz(moment.tz.guess(true))
                          .format("dddd MMMM DD, YYYY h:mmA z")
                      }}
                  </p>
                  <font-awesome-icon
                    class="float-right probook"
                    v-if="ins.bookmarked == false"
                    v-on:click.prevent="addVideo(ins.id)"
                    :icon="['far', 'bookmark']"
                  />
                  <font-awesome-icon
                    class="float-right probook"
                    v-else
                    v-on:click.prevent="removeVideo(ins.id)"
                    :icon="['fas', 'bookmark']"
                  />
                  <!-- <span class /="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../assets/live.png"/></span> -->
                </div>
              </router-link>
              <a v-else target="_blank" :href="ins.content_url">
                <div class="">
                  <img class="w-100" :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
                  <p class="d-inline-block w-95">
                    {{ ins.title }}
                    {{
                        moment(ins.start_at)
                          .tz(moment.tz.guess(true))
                          .format("dddd MMMM DD, YYYY h:mmA z")
                      }}
                  </p>
                  <font-awesome-icon
                    class="float-right probook"
                    v-if="ins.bookmarked == false"
                    v-on:click.prevent="addVideo(ins.id)"
                    :icon="['far', 'bookmark']"
                  />
                  <font-awesome-icon
                    class="float-right probook"
                    v-else
                    v-on:click.prevent="removeVideo(ins.id)"
                    :icon="['fas', 'bookmark']"
                  />
                  <!-- <span class="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../assets/live.png"/></span> -->
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="workouts">
        <div class="col-sm-12 p-0">
          <div class="row m-0">
            <h5>Other Sessions</h5>
            <button
              :disabled="prog.length > 0 && sB == 0"
              class="btn-link btn prev"
              @click="prevBook()"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button
              :disabled="prog.length > 0 && prog.length <= sB+1"
              class="btn-link btn"
              @click="nextBook()"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
        <div class="row m-0 erow w-100">
          <router-link
            class="col-lg-2 col-sm-4 pl-0"
            :to="{ name: 'Episodes', params: { id: ins.id } }"
            v-for="(ins, index) in prog.slice(sB)"
            :key="'category' + index"
          >
            <div class="workout">
              <img :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
              <!-- <p >{{ins.name}}</p> -->
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";

export default {
  name: "SavedPage",
  // props: ['window'],
  data() {
    return {
      page: "",
      sB: 0,
      sR: 0,
      sT:0,
      items: [],
      programs: [],
      categories: [],
      seasons: [],
      sessions: [],
      ebooks: [],
      user: {},
      keywords: "",
      plan: {},
      id: "",
      prog: [],
    };
  },
  created() {
    this.id = this.$session.get("user").id;
  },
  mounted() {
    this.getProfile();
  },
  computed: {},
  components: {},
  watch: {
    keywords: "searchSaved",
  },
  methods: {
      nextBook() {
        this.sB += 1;
    },
    prevBook() {
        this.sB -= 1;
    },
    nextVid() {
        this.sR += 1;
    },
    prevVid() {
        this.sR -= 1;
    },
    async getProg() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getPrograms();
        if (response.data.success == true) {
          this.prog = response.data.data;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getSaved() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getSaved();
        if (response.data.success == true) {
          this.saved = response.data.data;
          this.programs = response.data.data.programs;
          this.categories = response.data.data.categories;
          this.seasons = response.data.data.seasons;
          this.sessions = response.data.data.sessions;
          this.ebooks = response.data.data.ebooks;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async addBook(bid) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.saveBook(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getSaved();
          alert = {
            text: [],
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error");
          alert = {
            text: response.data.errors[0],
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async removeBook(bid) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.removeBook(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getSaved();
          alert = {
            text: [],
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error");
          alert = {
            text: response.data.errors[0],
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async searchSaved() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.searchSaved(this.keywords);
        if (response.data.success == true) {
          this.saved = response.data.data;
          this.programs = response.data.data.programs;
          this.categories = response.data.data.categories;
          this.seasons = response.data.data.seasons;
          this.sessions = response.data.data.sessions;
          this.ebooks = response.data.data.ebooks;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getCate() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getPrograms();
        if (response.data.success == true) {
          this.items = response.data.data;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async addVideo(bid) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.saveSession(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getSaved();
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async removeVideo(bid) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.removeSession(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getSaved();
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getProfile() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.id);
        if (response.data.success == true) {
          this.plan = response.data.data.profile.current_subscription;
          // console.log(this.plan)
          if (this.plan != null) {
            this.user.firstname = this.$session.get("user").firstname;
            this.user.lastname = this.$session.get("user").lastname;
            this.user.name = this.$session.get("user").name;
            this.getSaved();
            this.getCate();
            this.getProg();
             this.$session.set("subscription",true)
          } else {
             this.$session.set("subscription",false)
            router.push({
              path: "/customer/plans",
            });
          }
          // this.user.profile.avatar = response.data.data.profile.avatar.large
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
  },
};
</script>