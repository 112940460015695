// import ProgLayout from '@/views/layouts/admin'
import EpisodePage from '@/views/customer/episodes/all'


export default {

  path: '/customer/programs/:id/session',
  name: 'Episodes',
  meta:{title: 'Episodes',requiresAuth:true},
  component: EpisodePage
  
          
}
