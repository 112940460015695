import MainLayout from '@/views/layouts/main'
import AccountPage from '@/views/customer/account/myaccount'
import DashboardPage from '@/views/customer/home/dashboard'
import PlanPage from '@/views/customer/plan/all'
import SavePage from '@/views/customer/saved/all'
import InstructorPage from '@/views/customer/instructors/show'
import ProgramPage from '@/views/customer/programs/all'
import ShowEpisodePage from '@/views/customer/episodes/show'
import RedeemPage from '@/views/customer/coupons/redeem'
import WeekPage from '@/views/customer/schedule/week'
import ResourcesPage from '@/views/customer/home/resources'
import MasterclassPage from '@/views/customer/home/masterclass'

export default {
  path:'/customer/',
  component:MainLayout,
  meta:{requiresAuth:true},
  redirect: '/customer/dashboard' ,
  children: [
    {
      path: 'dashboard',
      name: 'Dashboardm',
      meta:{layout: 'main',title: 'Dasboard'},
      component: DashboardPage,
    },
    {
      path: 'masterclass',
      name: 'Masterclass',
      meta:{layout: 'main',title: 'Masterclass'},
      component: MasterclassPage,
    },
    {
      path: 'resources',
      name: 'Resources',
      meta:{layout: 'main',title: 'Resources'},
      component: ResourcesPage,
    },
    {
      path: 'dashboard/:cat',
      name: 'Dashboard',
      meta:{layout: 'main',title: 'Dasboard'},
      component: DashboardPage,
    },
    {
      path: 'account/:typ',
      name: 'My Account',
      meta:{layout: 'main',title: 'My Account'},
      component: AccountPage,
    },
    {
      path: 'account',
      name: 'Account',
      meta:{layout: 'main',title: 'My Account'},
      component: AccountPage,
    },
    {
      path: 'plans',
      name: 'Plans',
      meta:{layout: 'main',title: 'Plans'},
      component: PlanPage,
    },
    {
      path: 'redeem',
      name: 'RedeemCoupon',
      meta:{layout: 'main',title: 'Redeem'},
      component: RedeemPage,
    },
    {
      path: 'saved',
      name: 'MySaved',
      meta:{layout: 'main',title: 'My Saved'},
      component: SavePage,
    },
    {
      path: 'instructor/:id',
      name: 'ShowInstructor',
      meta:{layout: 'main',title: 'Instructor'},
      component: InstructorPage,
    },
    {
      path: 'categories/:id/programs',
      name: 'CatPrograms',
      meta:{layout: 'main',title: 'Program'},
      component: ProgramPage,
    },
   
    {
      path: 'session/:id',
      name: 'ShowEpisode',
      meta:{layout: 'main',title: 'ShowEpisodes'},
      component: ShowEpisodePage,
    },
    {
      path: 'schedule',
      name: 'WeeklySchedule',
      meta:{layout: 'main',title: 'Weekly'},
      component: WeekPage,
    }
          
  ]
}
