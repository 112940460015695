<template>
  <div id="new_user" class="col-lg-11 mt-4 adacc">
    <div class="col-lg-12">
    <h3><b>New Program</b></h3>
      <form class="" @submit.prevent="createProgram">
        <div class="mt-4">
         
          <div class="row mb-3">
             <div class="col-lg-4 col-md-4">
               <label>Program  Tags</label>  
              <v-select :options="tags"  :multiple="true" v-model="details.program.tag_ids" :reduce="label=>label.id " label="label" />
            </div>
            <!-- <div class="col-lg-3">
              <label> Program Category </label>
              <v-select :options="categories"  :multiple="false" v-model="details.program.program_category_id" :reduce="name=>name.id " label="name" />
              </div> -->
            <div class="col-lg-4 col-md-4">
               <label>Program Name </label>
                <input type="text" v-model="details.program.name"  class="input-field form-control" placeholder="" required />
            </div>
              <div class="col-lg-3 col-md-4">
               <label>Program Order </label>
                <input type="number" v-model="details.program.ordering"  class="input-field form-control" placeholder="" required />
            </div>
          </div>
          
          <div class="row mb-3">
            <div class="col-lg-4 col-md-6">
               <label>Program instructors</label>
              <v-select :options="instructors"  :multiple="true" v-model="details.program.instructor_ids" :reduce="name=>name.id " label="name" />
            </div>
            <div class="col-lg-6 col-md-6">
            <label class="col-lg-12 p-0">Avaliable On</label>
              <v-select :options="plans"  :multiple="true" v-model="details.program.plan_ids" :reduce="label=>label.id " label="name" />
            </div>
          </div>
          <div class="row ">
            <div class="col-lg-3 col-md-6">
              <label>Thumbnail</label>
              <br/>
              <input type="file" @change="getThumb" ref="thumbupload" />
            </div>
             <div class="col-lg-3 col-md-6">
              <label>Header Background</label>
              <br/>
              <input type="file" @change="getHead" ref="headupload"/>
            </div>
          </div>
          <div class="row mb-3">
             <div class="col-lg-3 col-md-6 mt-2">
                <img  class="av" v-if="details.program.thumbnail" :src="details.program.thumbnail" />
             </div>
              <div class="col-lg-3 col-md-6 mt-2">
                <img  class="av" v-if="details.program.header_background" :src="details.program.header_background" />
             </div>
          </div>
            <div class="row mb-4">
                    <div class="col-lg-3 col-md-12">
                         <label>Publish Program</label>
                        <input type="checkbox" v-model="details.program.mark_as_published" class="ml-3" placeholder="" />
                   </div>

                </div>
         
                                    <router-link to="/admin/programs" ><button class="form-btn mr-4 cancel">Cancel</button></router-link>

          <button  id="saveUser" type="submit" class="form-btn ">Save Program</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'
import router from '@/router'

export default {
  name: 'NewProgramPage',
  data () {
    return {    
      details:{
        program:{
          name:"",
          plan_ids: [],
          program_category_id:"",
          published:true,
          tag_ids:[],
          header_background:"",
          thumbnail:"",
          instructor_ids:[],
          ordering:0
          
        }
      },
      plans:[],
      categories:[],
      instructors:[],
      tags:[]
    }
  },
  created(){
    },
  computed:{
    
    },
  mounted(){
    this.getTypes()
  }, 
  components: {
  },      
  methods: {
     async getTypes(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.newPrograms();
        if(response.data.success == true){
          this.plans      = (response.data.data.options.plans)
           this.categories      = (response.data.data.options.program_categories)
           this.instructors     = (response.data.data.options.instructors)
           this.tags      = (response.data.data.options.tags)
           this.details.program.ordering =response.data.data.program.ordering
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
    async createProgram(){
   var alert= {}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        var bt= document.getElementById('saveUser')
        const response = await AdminService.createProgram(this.details);
        if(response.data.success == true){
          ////bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          // //bt.innerText = response.data.flash.notice 
          setTimeout(function(){  
       
          router.push({ path: '/admin/programs' }); 
      }, 500);  
        }
        else{
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Save Program" 
          }, 1500); 
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
    },
     getThumb(event){
         const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > 4) {
          alert('File size exceeds 4 MB');
          this.$refs.thumbupload.value=null
        }
        else{
      const fr = new FileReader()
      fr.onloadend = function(){
         this.details.program.thumbnail =fr.result
        }.bind(this)
        
      fr.readAsDataURL(event.target.files[0])
        }
    },
     getHead(event){
         const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > 4) {
          alert('File size exceeds 4 MB');
          this.$refs.headupload.value=null
        }
        else{
      const fr = new FileReader()
      fr.onloadend = function(){
         this.details.program.header_background =fr.result
        }.bind(this)
        
      fr.readAsDataURL(event.target.files[0])
        }
    },

  }  
}
</script>