import mainInstance from '@/services/api'

var sub = "http://wellfit.1greatstudio.com/customer/account/subscription"
// var sub = "https://members.thewellfitclub.com/customer/account/subscription"

export default {
  getCategories(){
    try{
      return mainInstance().get('program-categories');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showCategory(data){
    try{
      return mainInstance().get('program-categories/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getPrograms(){
    try{
      return mainInstance().get('programs?order_by=ordering&order_dir=asc');
    }catch(err){
      //console.log(err)
      return err
    }
  },

  getTags(){
    try{
      return mainInstance().get('tags');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getSettings(){
    try{
      return mainInstance().get('settings');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getTagPrograms(data){
    try{
      return mainInstance().get('programs?tags='+data+'&order_by=ordering&order_dir=asc');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getTagBooks(data){
    try{
      return mainInstance().get('ebooks?tags='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
   getResBooks(data){
    try{
      return mainInstance().get('ebooks?tags='+data +'&order_by=ordering&order_dir=asc');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getTagSessions(data,pg){
    try{
      return mainInstance().get('sessions?tags='+data+'&status=recent&order_by=start_at&order_dir=desc&page='+pg);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getCouponPlans(data){
    try{
      return mainInstance().get('coupons?code='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getSession(data,pg){
    try{
      return mainInstance().get('programs/'+data+'/sessions?order_by=start_at&order_dir=desc&page='+pg);
    }catch(err){
      //console.log(err)
    }
  },
  searchSession(data,term,page){
    try{
      return mainInstance().get('programs/'+data+'/sessions?term='+term+'&order_by=start_at&order_dir=desc&page='+page);
    }catch(err){
      //console.log(err)
    }
  },
  showProgram(data){
    try{
      return mainInstance().get('program-categories/'+data+'/programs');
    }catch(err){
      //console.log(err)
      return err
    }
  },

  getProgram(data){
    try{
      return mainInstance().get('programs/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getSeasons(){
    try{
      return mainInstance().get('seasons');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showSeason(data){
    try{
      return mainInstance().get('seasons/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },

  getSessions(){
    try{
      return mainInstance().get('sessions?order_by=start_at&order_dir=desc');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getRecentSessions(){
    try{
      return mainInstance().get('sessions?status=recent&order_by=start_at&order_dir=desc');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  
  showSession(data){
    try{
      return mainInstance().get('sessions/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getProfile(){
    try{
      return mainInstance().get('profile');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateProfile(data){
    try{
      return mainInstance().put('profile',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getPlans(){
    try{
      return mainInstance().get('plans');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getPresenters(){
    try{
      return mainInstance().get('presenters?order_by=ordering&order_dir=asc');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showPresenter(data){
    try{
      return mainInstance().get('presenters/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getBooks(){
    try{
      return mainInstance().get('ebooks');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  showBook(data){
    try{
      return mainInstance().get('ebooks/'+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },

  getInsVideos(data,pg){
    try{
      return mainInstance().get('sessions?presenter='+data+'&order_by=start_at&order_dir=desc&page='+pg);
    }catch(err){
      //console.log(err)
      return err
    }
  },

  weekSessions(from,to,tag){
    try{
      return mainInstance().get('sessions?from='+from+'&to='+to+tag+'&platforms=all&order_dir=asc');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getSaved(){
    try{
      return mainInstance().get('bookmarks');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  searchSaved(data){
    try{
      return mainInstance().get('bookmarks?term='+data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  saveSession(data){
    try{
      return mainInstance().post('sessions/'+data+'/bookmark');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  removeSession(data){
    try{
      return mainInstance().delete('sessions/'+data+'/bookmark');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  saveBook(data){
    try{
      return mainInstance().post('ebooks/'+data+'/bookmark');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  removeBook(data){
    try{
      return mainInstance().delete('ebooks/'+data+'/bookmark');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  removeSubscription(data){
    try{
      return mainInstance().delete('plans/'+data+'/unsubscribe');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  newSubscription(plan,data){
    try{
      return mainInstance().post('plans/'+plan+'/subscribe',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getCard(){
    try{
      return mainInstance().get('cards');
    }catch(err){
      //console.log(err)
      return err
    }
  },
    getStripe(){
    try{
      return mainInstance().get('get-stripe-session?stripe_return_url='+sub);
    }catch(err){
      //console.log(err)
      return err
    }
  },
  getSubAuto(){
    try{
      return mainInstance().get('subscription');
    }catch(err){
      //console.log(err)
      return err
    }
  },
  updateSubAuto(data){
    try{
      return mainInstance().put('subscription',data);
    }catch(err){
      //console.log(err)
      return err
    }
  },

  getSubPay(){
    try{
      return mainInstance().get('payments');
    }catch(err){
      //console.log(err)
      return err
    }
  },
}