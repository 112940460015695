<template>
  <div id="main-layout" class="wf-back">
     <div  v-if="salert"
              class="alert wal alert-dismissible fade show"
              :class="alert.type"
              role="alert"
              :style="alert.type ? 'display:block;position:fixed ' : 'display:none'"
            >
            <div id="alert" >
              <p class="alert-heading m-0">
                <strong>{{ alert.head }}</strong>
                <span class="alert-text ml-2">{{ alert.text }}</span>
              </p>
            </div>
            </div>
    <div class="sess-det">
      <div v-if="flash" class="alert" :class="flash_type" role="alert">
        {{ flash_msg }}
      </div>
      <nav class="navbar navbar-expand-lg main-nav">
        <router-link
          class="navbar-brand ml-0"
          to="/customer/dashboard"
          @click.native="page = ''"
          ><img class="dash-logo" src="../../assets/wellfit-logo@2x.png"
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"
            ><i class="fas fa-bars fa-lg"></i
          ></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav nav">
            <router-link
              :to="{ name: 'Dashboard', params: { cat: 'move' } }"
              :class="
                this.$route.params.cat == 'move'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('move', 0)"
              >Workout Videos</router-link
            >
            <router-link
              :to="{ name: 'Dashboard', params: { cat: 'eat' } }"
              :class="
                this.$route.params.cat == 'eat'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('eat', 1)"
              >Eat Well</router-link
            >
            <router-link
              :to="{
                path: '/customer/masterclass',
                params: { cat: 'masterclass' },
              }"
              :class="
                this.$route.params.cat == 'masterclass'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('masterclass', 2)"
              >Masterclass</router-link
            >
            <router-link
              :to="{
                path: '/customer/schedule',
                params: { cat: 'schedule' },
              }"
              :class="
                this.$route.params.cat == 'schedule'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('schedule', 3)"
              >Schedule</router-link
            >
            <router-link
              :to="{ name: 'Resources', params: { cat: 'resources' } }"
              :class="
                this.$route.params.cat == 'resources'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('resources', 4)"
              >Resources</router-link
            >
            <router-link
              v-if="promo == true"
              :to="{ name: 'Dashboard', params: { cat: 'promotional' } }"
              :class="
                this.$route.params.cat == 'promotional'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('promotional', 5)"
              >{{ tag.label }}</router-link
            >
            <a class="nav-link pl-0" href="https://www.thewellfitclub.com/shop"
              >Store</a
            >
            <!-- <router-link :to="{ name: 'Dashboard', params: { cat: 'drink' }}" :class="this.$route.params.cat=='drink' ?'nav-link pl-0 active' :'nav-link pl-0 '" @click.native="loadPage('drink',2)">Drink</router-link>
            <router-link :to="{ name: 'Dashboard', params: { cat: 'rest' }}" :class="this.$route.params.cat=='rest' ?'nav-link pl-0 active' :'nav-link pl-0 '" @click.native="loadPage('rest',3)">Rest</router-link>
            <router-link :to="{ name: 'Dashboard', params: { cat: 'live' }}" :class="this.$route.params.cat=='live' ?'nav-link pl-0 active' :'nav-link pl-0 '" @click.native="loadPage('live',4)">Live</router-link> -->
          </div>
        </div>
        <div class="user float-right">
          <router-link to="/customer/saved" class=""
            ><i class="fas fa-bookmark mr-3"></i
          ></router-link>
          <img class="mr-2" v-if="user.avatar" :src="user.avatar" />
          <img class="mr-2" v-if="user.avatar_urls" :src="user.avatar_urls" />
          <p
            class="mb-0 dropdown-toggle"
            href="#"
            role="button"
            id="usernameL"
            data-toggle="dropdown"
            aria-haspopup="true"
            v-if="user.firstname && user.lastname"
          >
            {{ user.firstname }} {{ user.lastname }}
          </p>
          <p
            class="mb-0 dropdown-toggle"
            v-else
            href="#"
            role="button"
            id="usernameL"
            data-toggle="dropdown"
            aria-haspopup="true"
          >
            {{ user.name }}
          </p>
          <div class="dropdown show">
            <a
              class="dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-angle-down"></i>
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <router-link to="/customer/account" class="dropdown-item"
                >My Account</router-link
              >
              <router-link to="/customer/saved" class="dropdown-item"
                >Saved</router-link
              >
              <!-- <router-link to="/customer/schedule" class="dropdown-item" >Schedule</router-link> -->
              <router-link to="/customer/redeem" class="dropdown-item"
                >Redeem Coupon</router-link
              >
              <!-- <router-link to="/customer/plans" class="dropdown-item" >Plans</router-link> -->
              <a
                class="dropdown-item"
                href="https://www.thewellfitclub.com/about"
                >About Us</a
              >
              <a
                class="dropdown-item"
                href="https://www.thewellfitclub.com/faqs"
                >FAQs</a
              >
              <a class="dropdown-item" @click="logout()">Log Out</a>
            </div>
          </div>
        </div>
      </nav>
      <div class="mainR">
        <keep-alive>
        <router-view :window="window" />
        </keep-alive>
      </div>
    </div>
    <div id="sess-footer">
      <div class="foot-details">
           <span class="pby">powered by</span>
        <img class="powered-logo" src="../../assets/Canopy-Logo.png" />
        <p class="mb-0 d-inline-block ml-3 res">
          2020 WellFit Ltd. All Rights Reserved.
          <br />
          <a href="/">Privacy Policy </a>/ <a href="/">Terms of Use</a>
        <a class="ogs" href="https://www.onegreatstudio.com/" target="_blank">
          <img class="" src="../../assets/1gs-logo@2x.png" />
        </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import custom from "../../javascript/custom";
import router from "@/router";
import UserService from "../../services/userService";
import axios from "axios";
import $ from "jquery";

export default {
  name: "MainPage",
  data() {
    return {
      page: "",
      flash: false,
      flash_type: "",
      flash_msg: "",
      tag: {},
      promo: false,
      user: {
        firstname: "",
        lastname: "",
        name: "",
        avatar: "",
        avatar_urls: "",
      },
      id: "",
      window: {
        width: 0,
        height: 0,
      },
      alert: {
        text: "",
        head: "",
        type: "",
      },

    salert:false

    };
  },
  created() {
    this.id = this.$session.get("user").id;
  },
  computed: {},
  mounted() {
    this.getProfile();
    this.$bus.$on("cusup", () => {
      this.getProfile();
    });
    this.$bus.$on("flash", (data) => {
      this.alert.text = data.text;
      if (this.alert.text[0] != "") {
        this.alert.text = this.alert.text[0];
      }
      this.alert.head = data.head;
      this.alert.type = data.type;
      this.salert=true;
      setTimeout(function () {
                   if( document.getElementById("alert") != null){
             document.getElementById("alert").classList.remove("d-block");
           }
        this.removeAl()
      }.bind(this), 5000);
    });


  },

  Updated(){
    this.actSlide()
  },
  components: {},

  methods: {
    removeAl(){
      this.salert=false;
      delete this.alert.type
    },

    actSlide(){
      const slider = document.querySelector('.erow');
let isDown = false;
let startX;
let scrollLeft;

slider.addEventListener('mousedown', (e) => {
  isDown = true;
  slider.classList.add('active');
  startX = e.pageX - slider.offsetLeft;
  scrollLeft = slider.scrollLeft;
});
slider.addEventListener('mouseleave', () => {
  isDown = false;
  slider.classList.remove('active');
});
slider.addEventListener('mouseup', () => {
  isDown = false;
  slider.classList.remove('active');
});
slider.addEventListener('mousemove', (e) => {
  if(!isDown) return;
  e.preventDefault();
  const x = e.pageX - slider.offsetLeft;
  const walk = (x - startX) * 3; //scroll-fast
  slider.scrollLeft = scrollLeft - walk;
  console.log(walk);
});
    },
  
    loadPage(pg, na) {
      this.page = pg;
      custom.updateNav(na);
      $(".navbar-toggler").click();
    },
    logout() {
      this.$session.clear();
      sessionStorage.clear();
      localStorage.clear();
      this.$session.destroy();
      router.push({ path: "/" });
    },
    async getProfile() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.id);
        if (response.data.success == true) {
          this.user = response.data.data.profile;
          this.user.avatar = response.data.data.profile.avatar.large;
          this.getTags();
        } else {
          //console.log("error");
        }
      } catch (err) {
        if((err.response.status)==401){

          this.logout() 
        }
      }
    },
    async getTags() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getTags();
        if (response.data.success == true) {
          for (var x = 0; x < response.data.data.length; x++) {
            if (response.data.data[x].promo == true) {
              this.tag = response.data.data[x];
              this.$session.set("promo", this.tag.label);
              this.promo = true;
            }
          }
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
  },
};
</script>