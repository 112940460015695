<template>
  <div id="new_user" class="col-lg-11 mt-4 mb-5 pb-3 adacc">
    <h3><b>New Link</b></h3>
    <div class="">
      <form  @submit.prevent="createBook">
        <div class="mt-4">

          <div class="row mb-4">
            <div class="col-lg-3 col-md-6">
              <label>Title</label>
              <input type="text" v-model="details.ebook.title" class="input-field form-control" placeholder="" required />
            </div>
            <div class="col-lg-3 col-md-6">
               <label>Author Name</label>
                <input type="text" v-model="details.ebook.author" class="input-field form-control" placeholder="" required />
            </div>
             <div class="col-lg-3 col-md-4">
               <label>Link Order </label>
                <input type="number" v-model="details.ebook.ordering"  class="input-field form-control" placeholder="" required />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-lg-6 col-md-6">
              <label>Description (140 characters)</label>
              <textarea rows="4" v-model="details.ebook.description" class="input-field form-control mt-0" maxlength="140"></textarea>
            </div>
            
          </div>
          <div class="row mb-4">
            <div class="col-lg-3 col-md-4">
              <label>Thumbnail</label>
              <br/>
              <input type="file"  @change="getThumb" ref="thumbupload" />
            </div>
              <div class="col-lg-3 col-md-4">
               <label>Content Type</label>
               <select v-model="details.ebook.content_type"  class="input-field form-control">
                 <option :value="ty" v-for="(ty,index) in types" :key="'ty'+index">{{ty.toUpperCase()}}</option>
                  <!-- <option value="file">File Upload</option> -->
               </select>
            </div>
            <div class="col-lg-3 col-md-4" v-if="details.ebook.content_type=='url'">
               <label>Content URL</label>
                <input type="text" v-model="details.ebook.content_url" class="input-field form-control" placeholder="" required />
            </div>
             <div class="col-lg-5 col-md-5"  v-if="details.ebook.content_type=='file upload'">
              <label>File Upload</label>
              <br/>
              <input type="file"  @change="getFile" accept=".pdf" ref="fileupload" :required="!details.ebook.download_url ? true : false"/>
            </div>

             <div class="col-lg-4 col-md-4 mt-2">
                <img  class="av" v-if="details.ebook.thumbnail" :src="details.ebook.thumbnail" />
             </div>
             <div class="col-lg-2"></div> 
                           <div class="col-lg-5 col-md-5 mt-2" v-if="details.ebook.content_type=='file upload'">
                <a target="_blank" style="color:#101010 !important" :href="details.ebook.download_url" > {{details.ebook.download_url}}</a>
             </div>
          </div>
          <div class="row mb-4">
                        <div class="col-lg-3 col-md-4">
                  <label>Tags</label>
              <v-select :options="tags"  :multiple="true" v-model="details.ebook.tag_ids" :reduce="label=>label.id " label="label" />
            </div>
            <div class="col-lg-4 col-md-5">
              <label>Available On</label>
               <v-select :options="plans"  :multiple="true" v-model="details.ebook.plan_ids" :reduce="name=>name.id " label="name"  />
            </div>

          </div>
                                   <router-link to="/admin/links" ><button class="form-btn mr-4 cancel">Cancel</button></router-link>

          <button id="savePl" type="submit" class="form-btn ">Save Link</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'
import router from '@/router'

export default {
  name: 'NewEbookPage',
  data () {
    return { 
      details:{
        ebook:{
          content_host_types:[],
          title:"",
          description:"",
          author:"",
          plan_ids:[],
          published:true,
          thumbnail:"",
          tag_ids:[],
          content_url:"",
          download:"",
          poster:"",
          content_type:"url",
          ordering:0
        }
      } ,  
      plans:[],
      tags:[],
      categories:[],
      types:[]
    }
  },
  created(){
    },
  computed:{
    
    },
  mounted(){
    this.getTypes()
  }, 
  components: {
  },      
  methods: {
     async getTypes(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.newEbook();
        if(response.data.success == true){
            this.categories  = (response.data.data.options.program_categories)
           this.tags   = (response.data.data.options.tags)
           this.plans     = (response.data.data.options.plans)
           this.types = response.data.data.options.content_types
          this.details.ebook.ordering =response.data.data.ebook.ordering

        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
           getFile(event){

      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > 4) {
          alert('File size exceeds 4 MB');
          this.$refs.fileupload.value=null
          // $(this).val(''); //for clearing with Jquery
        } 
        else {
                  const fr = new FileReader()
      fr.onloadend = function(){
         this.details.ebook.download =fr.result
        }.bind(this)
        
      fr.readAsDataURL(event.target.files[0])
        }
  
  //  this.details.ebook.download=event.target.files[0]
    },
     getThumb(event){
       const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > 4) {
          alert('File size exceeds 4 MB');
          this.$refs.thumbupload.value=null
        }
        else{

          const fr = new FileReader()
          fr.onloadend = function(){
             this.details.ebook.thumbnail =fr.result
            }.bind(this)
            
          fr.readAsDataURL(event.target.files[0])
        }
    },
     async createBook(){
       var alert= {}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        var bt= document.getElementById('savePl')
         if(this.details.ebook.content_type=='url'){
          delete this.details.ebook.download
        }
        else{
            delete this.details.ebook.content_url
        }
                  // let formData = new FormData()
        if(this.details.ebook.download !="" || this.details.ebook.download !={}){
          // const form = document.querySelector("#linkform");
          // this.details= new FormData(form)
            // formData.append("ebook[title]", this.details.ebook.title)
            // formData.append("ebook[description]", this.details.ebook.description)
            // formData.append("ebook[author]", this.details.ebook.author)
            // formData.append("ebook[plan_ids]", JSON.stringify(this.details.ebook.plan_ids))
            // formData.append("ebook[published]", this.details.ebook.published)
            //   formData.append("ebook[content_type]", this.details.ebook.content_type)
            //   formData.append("ebook[tag_ids]", JSON.stringify(this.details.ebook.tag_ids))

            // //  formData.append("ebook[content_host_types]", this.details.ebook.content_host_types)
            //     if(this.details.ebook.thumbnail  && this.details.ebook.thumbnail.includes("data:image")!=false){
            //        formData.append("ebook[thumbnail]", this.details.ebook.thumbnail)
            //     }
            //       if(this.details.ebook.poster && this.details.ebook.poster.includes("data:image")!=false){
            //          formData.append("ebook[poster]", this.details.ebook.poster)
            //       }
            //         if(this.details.ebook.content_url){
            //               formData.append("ebook[content_url]", this.details.ebook.content_url)
            //     }
            //       if( this.details.ebook.download && this.details.ebook.download.includes("uploads")==false){
            //                   formData.append("ebook[download]", this.details.ebook.download)
            //       }

        }
        const response = await AdminService.createEbook(this.details);
        if(response.data.success == true){
          ////bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          // //bt.innerText = response.data.flash.notice  
          setTimeout(function(){  
      
          router.push({ path: '/admin/links' }); 
      }, 500); 
        }
        else{
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Save Link" 
          }, 1500); 
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
    },

  }  
}
</script>