<template>
  <div>
     <div class="table-list users-list mt-4 mb-4">
       <div class="row">
         <div class="col-lg-4 col-md-4 d-flex">
          <h4 class="mb-0"><b>Transactions</b></h4>
          
         </div>
         <div class="col-lg-5 col-md-5">
              
            <div class="row">
              <div class="col-lg-6 col-md-6 ">
              <datetime type="date" zone="local" title="From Date" placeholder="From Date" value-zone="EST" input-class="search" v-model="filfrom" ></datetime>

              </div>
              <div class="col-lg-6 col-md-6 ">
              <datetime type="date" zone="local"  title="To Date" placeholder="To Date" value-zone="EST" input-class="search" v-model="filto" ></datetime>

              </div>
            </div>
         </div>
         <div class="col-lg-3 col-md-3 rbtn">
            <download-csv
              :data   = "plans" class="form-btn csvbtn">
              Download CSV
              
          </download-csv>
          </div>
        
       </div>
      <!-- <paginate
        name="plans"
        :list="plans"
        :per="lim"
        class="pl-0"
      > -->
        
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Amount</th>
              <th scope="col">Plan</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
              <tr class="table-det spacer" v-for="(plan,index) in plans" :key="plan+index">
                <td>{{plan.customer_name}}</td>
                <td>{{plan.customer_email}}</td>
                <td>${{plan.amount | numFormat }} </td>
                <td>{{plan.plan_name}}</td>
                <td>{{moment(plan.paid_on).format('LL')}}</td>
                <!-- <td>{{plan.trial_subscribers_count}}</td>
                <td>{{plan.expired_subscribers_count}}
                  <font-awesome-icon  v-on:click.prevent="remItem(plan.id)" class="float-right" :icon="['fal', 'times']" />
                </td> -->
              </tr>
          </tbody>
        </table>
         <ul class="pl-0 paginate-links">
     <li v-if="pages>0"  @click="getTransactions(1)" class="left-arrow disabled">
       <a>«</a>
       </li>

       <li @click="getTransactions(p)" :class="p == current ? 'number active pg' : 'number pg'" v-for="(p,index) in pages " :key="pag+index" >
       <span   v-if="Math.abs(p - current) < 4 || p == pages  || p== 1" >
         
         <a :class="{current: current=== p, last: (p == pages  && Math.abs(p- current) > 4), first:(p == 1 && Math.abs(p - current) > 4)}" >{{p}}</a>
       </span>
         </li>
       <li  v-if="pages>0" @click="getTransactions(pages)" class="right-arrow disabled"><a>»</a></li>
    </ul>
      <!-- </paginate> -->
      <!-- <paginate-links class="pl-0" for="plans" :async="true" :limit="lim" :show-step-links="true"></paginate-links> -->
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'

export default {
  name: 'TransactionsPage',
  data () {
    return {    
      paginate: ['plans'],
      lim:10,
      plans:[],
      fildate:"",
      filto:"",
      filfrom:"",
            current:1,
      pages:0,
    }
  },
  created(){
    },
  computed:{
    
    },
  mounted(){
    this.getTransactions(this.current)
    
  }, 
 
  components: {
  },   
  watch:{
    filfrom:'getTransactions',
    filto:'getTransactions'
  }  , 
  methods: {
     async getTransactions(page){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getTransactions(this.filfrom,this.filto,page);
        if(response.data.success == true){
          this.plans = (response.data.data.records)   
               this.pages=   response.data.pagination.total_pages
          this.current = response.data.pagination.page      
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
     
    async getItems(date){
      alert(date)
    }
  }  
}
</script>