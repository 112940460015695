<template>
  <div>
     <div class="table-list users-list mt-4">
       <div class="row">
         <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0"><b>Users</b></h4>
          <router-link to="/admin/users/new" ><button class="form-btn ml-4">Add New</button></router-link>
          
         </div>
           
          <div class="col-lg-6 col-md-6 text-right rbtn">
            <a @click="getCSV()"><button class="form-btn">Export CSV</button></a>  
            <!-- <router-link to="/admin/roles" ><button class="form-btn">Manage Roles</button></router-link> -->
         </div>
       </div>
       <div class="row mt-4">
          <div class="col-lg-3 filselect col-md-3">
              <v-select :options="roles"  placeholder="Roles" :multiple="true" v-model="roles_sel" :reduce="label=>label.id " label="label" />
            </div>
            <div class="col-lg-3 filselect col-md-3">
              <v-select :options="plans" :multiple="true" placeholder="Plans" v-model="plans_sel" :reduce="label=>label.id " label="name" />
            </div>
            <div class="col-lg-3 filinp col-md-3">
             <input type="text" placeholder="Name" v-model="sename"/>
            </div>
            <div class="col-lg-2 col-md-2">
              <button @click="getFilUsers(current)" class="form-btn">Search</button>
            </div>
       </div>
       <div class="row mt-3">
           <div class="col-lg-3 filinp col-md-3">
             <input type="text" placeholder="Email" v-model="seemail"/>
            </div>
       </div>
      <!-- <paginate
        name="orderedUsers"
        :list="orderedUsers"
        :per="lim"
        class="pl-0"
      > -->
        
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
               <th scope="col">Plan</th>
              <th scope="col">Date Added</th>
            </tr>
          </thead>
          <tbody>
            <router-link  class="link_row" :to="{ name: 'EditUser', params: { id: us.id }}" v-for="(us,index) in users  " :key="user+index">
            <tr class="table-det spacer">
              <td>{{us.firstname}} {{us.lastname}}</td>
              <td>{{us.email}}</td>
              <td><span class="mr-2" v-for="(rl,index) in us.roles" :key="role+index">{{rl.label}}</span><span v-if="us.roles.length==0">Subscriber</span></td>
                            <td><span class="mr-2" v-if="us.plan">{{us.plan.name}}</span></td>
              <td>
                {{moment(us.created_at).format('LL')}}
                 <font-awesome-icon v-if="id!=us.id"  v-on:click.prevent="remItem(us.id)" class="float-right" :icon="['fal', 'times']" />
              </td>
            </tr>
            </router-link>
          </tbody>
        </table>
         <ul class="pl-0 paginate-links">
     <li v-if="pages>0"  @click="getFilUsers(1)" class="left-arrow disabled">
       <a>«</a>
       </li>

       <li @click="getFilUsers(p)" :class="p == current ? 'number active pg' : 'number pg'" v-for="(p,index) in pages " :key="pag+index" >
       <span   v-if="Math.abs(p - current) < 4 || p == pages  || p== 1" >
         
         <a :class="{current: current=== p, last: (p == pages  && Math.abs(p- current) > 4), first:(p == 1 && Math.abs(p - current) > 4)}" >{{p}}</a>
       </span>
         </li>
       <li  v-if="pages>0" @click="getFilUsers(pages)" class="right-arrow disabled"><a>»</a></li>
    </ul>
   


      <!-- </paginate> -->
      <!-- <paginate-links class="pl-0" for="orderedUsers" :async="true" :limit="lim" :show-step-links="true"></paginate-links> -->
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'
import { saveAs } from 'file-saver';


export default {
  name: 'UsersPage',
  data () {
    return {    
      paginate: ['orderedUsers'],
      lim:10,
      users:[],
      id:"",
         current:1,
      pages:0,
      roles:[],
      plans:[],
      roles_sel:[],
      plans_sel:[],
      sename:"",
      seemail:""
    }
  },
  created(){
    this.id=this.$session.get("user").id
  },
  computed:{
    orderedUsers: function () {
      return this._.orderBy(this.users, 'id')
    },
    
  },
  mounted(){
      this.getFilUsers(this.current)
      this.getRoles()
      this.getPlans()
  }, 
  components: {
  },      
  methods: {
    
async getCSV(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
          var fil =''
        for(var i = 0;i<this.roles_sel.length;i++){
          fil+='&role_ids[]='+this.roles_sel[i]
        }
        for(var x = 0;x<this.plans_sel.length;x++){
          fil+='&plan_id='+this.plans_sel[x]
        }
      fil+="&name="+this.sename
      fil+="&email="+this.seemail

      fil =  fil.replace('&','');

        const response = await AdminService.getUserCSV(fil);
        var users = response.data
   
        var file = new File([users], {type: "text/plain;charset=utf-8"});
        // FileSaver.saveAs(file);   
        saveAs(file, "Users.csv");
      } catch (err){
        //console.log(err)
      }
    },
    async getRoles(page){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getRoles(page);
        if(response.data.success == true){
          this.roles = (response.data.data) 
          this.roles[2]={id:[],label:"Subscriber"}  
          //      this.pages=   response.data.pagination.total_pages
          // this.current = response.data.pagination.page        
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
      async getPlans(page){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getPlans(page);
        if(response.data.success == true){
          this.plans = (response.data.data)   
          // this.pages=   response.data.pagination.total_pages
          // this.current = response.data.pagination.page        
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
      async getFilUsers(page){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
      var fil =''
      for(var i = 0;i<this.roles_sel.length;i++){
        fil+='&role_ids[]='+this.roles_sel[i]
      }
      for(var x = 0;x<this.plans_sel.length;x++){
        fil+='&plan_id='+this.plans_sel[x]
      }
      fil+="&name="+this.sename
      fil+="&email="+this.seemail
       try{
        const response = await AdminService.getFilUsers(page,fil);
        if(response.data.success == true){
          this.users = (response.data.data)   
              this.pages=   response.data.pagination.total_pages
          this.current = response.data.pagination.page      
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
     async getUsers(page){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getUsers(page);
        if(response.data.success == true){
          this.users = (response.data.data)   
              this.pages=   response.data.pagination.total_pages
          this.current = response.data.pagination.page      
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
    remItem(litem){
       if(confirm("Do you really want to delete?")){
        this.remove(litem)
      }
    },
    async remove(it){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.deleteUser(it);
        if(response.data.success == true){
          this.getFilUsers()
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>