<template>
  <div id="account">
    <div class="row mt-5">
      <div class="col-lg-3">
        <h2>My Account</h2>
        <div class="sidenav mt-4">
          <nav class="nav flex-column">
            <router-link :to="{ name: 'Account'}"  :class="this.$route.name=='Account' ?'nav-link pl-0 active' :'nav-link pl-0'" @click.native="loadPage('profile',0)">User Profile</router-link>
            <!-- <router-link :to="{ name: 'My Account', params: { typ: 'payment' }}" :class="this.$route.params.typ=='payment' ?'nav-link pl-0 active' :'nav-link pl-0 '" @click.native="loadPage('payment',1)">Payment Details</router-link> -->
            <router-link :to="{ name: 'My Account', params: { typ: 'subscription' }}"  :class="this.$route.params.typ=='subscription' ?'nav-link pl-0 active' :'nav-link pl-0 '" @click.native="loadPage('subscriptions',2)">Subscription</router-link>
            <router-link :to="{ name: 'My Account', params: { typ: 'billing-history' }}"  :class="this.$route.params.typ=='billing-history' ?'nav-link pl-0 active' :'nav-link pl-0 '" @click.native="loadPage('billing-history',3)">Billing History</router-link>
            
          </nav>
        </div>
      </div>
      <div class="col-lg-9">
        <Subscriptions v-if="this.$route.params.typ=='subscription'"></Subscriptions>
        <!-- <Payment v-else-if="this.$route.params.typ=='payment'"></Payment> -->
        <Billing v-else-if="this.$route.params.typ=='billing-history'"></Billing>
        <Profile v-else></Profile>
      </div>
    </div>
  </div>
</template>

<script>
// import custom from '../../../javascript/custom'
import Profile from './profile'
// import Payment from './payment'
import Billing from './billing'
import Subscriptions from './subscriptions'

export default {
  name: 'AccountPage',
  data () {
    return {    
      page:"profile",
      // plan:""
    }
  },
  created(){
  
  },
  computed:{

  },
  mounted(){
 
  },
  components: {
    Profile,
    Subscriptions,
    // Payment,
    Billing
  },      
  methods: {
    loadPage(pg){
      this.page=pg;
      // custom.updateAccNav(na)
    }
  }  
}
</script>