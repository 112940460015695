<template>
  <div id="new_user " class="col-lg-11 mt-4 adacc">
    <div class="col-lg-12">
    <h3><b>My Account</b></h3>
      <form class="" @submit.prevent="createUser">
        <div class="mt-4">
         <div class="row mb-3">
            <div class="col-lg-6 col-md-6">
              <label>Role</label>
             <v-select :options="roles"  multiple v-model="details.user.role_ids"  :reduce="name=>name.id " label="label" :selectable="() => details.user.role_ids.length < 1" />
            <!-- <select v-model="details.user.role_ids" class="input-field form-control" >
                <option v-for="option in roles" v-bind:value="option.id" v-bind:key="option">
                  {{ option.label }}
                </option>
              </select> -->
            </div>
          </div>

          <div class="row  mb-3">
            <div class="col-lg-12">
              <label>Avatar</label>
              <br/>
              <input type="file" @change="getThumb" ref="thumbupload" />
            </div>
             <div class="col-lg-12 mt-2">
                <img  class="av" v-if="details.user.avatar" :src="details.user.avatar" />
             </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-3 col-md-6">
              <label>First Name *</label>
              <input type="text" v-model="details.user.firstname"  class="input-field form-control" placeholder="" required />
            </div>
            <div class="col-lg-3 col-md-6">
               <label>Last Name *</label>
                <input type="text" v-model="details.user.lastname"  class="input-field form-control" placeholder="" required />
            </div>
            <div class="col-lg-6">
               <label>Email Address *</label>
              <input type="email" v-model="details.user.email" class="input-field form-control" placeholder="" required readonly />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6">
               <label>New Password </label>
             <input :type="passwordFieldType" v-on:keyup="validate"  v-on:keydown="validate" v-model="details.user.password" class="input-field form-control" placeholder="" minlength="8"/>
              <span type="password" @click="switchVisibility"><i class="far fa-eye"></i></span>

            </div>
            <div class="col-lg-6 col-md-6">
               <label>Confirm New Password </label>
              <input :type="confirmpasswordFieldType" v-on:keyup="validate"  v-on:keydown="validate" v-model="details.user.password_confirmation" class="input-field form-control" placeholder="" minlength="8"/>
              <span type="password" @click="switchVisibilityconfirm"><i class="far fa-eye"></i></span>

            </div>
            <span class="col-lg-12 mb-3 form-helper nomatch">
              Password must match the one typed before.                    
            </span>
          </div>
           <!-- <div class="row">
            <div class="col-lg-6 col-md-12">
               <label>Date of Birth</label>
            <vue-dob-picker day="3" select-class="dob_select" class="input-field form-control mt-0"  v-model="details.user.date_of_birth" show-labels="false" month-format="long"></vue-dob-picker>
            </div>
           
          </div> -->
        
          <button  id="saveUser" type="submit" class="form-btn ">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import custom from '../../../javascript/custom'
import AdminService from '../../../services/adminService'
import axios from 'axios'

export default {
  name: 'AccountPage',
  data () {
    return {    
      details:{
        user:{
          password:"",
          email:"",
          role_ids:[],
          firstname:"",
          lastname:"",
          date_of_birth:null,
          active:false,
          avatar:"",
          password_confirmation:""
        }
      },
      confirm_password:"",
      passwordFieldType: 'password',
      confirmpasswordFieldType: 'password',
      roles:[],
      id:"",
      avatar:""
    }
  },
  created(){
    this.id=this.$session.get("user").id
  
  },
  computed:{
    
    },
  mounted(){
    this.getRoles()
    this.getUser();
   
  }, 
  components: {
  },      
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      if (this.passwordFieldType == 'text'){
        custom.showPassword(0)
      }
      else{
        custom.hidePassword(0)
      }
    },
     switchVisibilityconfirm() {
      this.confirmpasswordFieldType = this.confirmpasswordFieldType === 'password' ? 'text' : 'password'
      if (this.confirmpasswordFieldType == 'text'){
        custom.showPassword(1)
      }
      else{
        custom.hidePassword(1)
      }
    },
    validate(){
      if(this.details.user.password != ""){
        if(this.details.user.password != this.details.user.password_confirmation){
          custom.validatePassword(false)
        }
        else{
          custom.validatePassword(true)
        }
      }
    },
    async getRoles(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getRoles();
        if(response.data.success == true){
         this.roles=response.data.data
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }
    },
      async createUser(){
        var alert= {}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        var bt= document.getElementById('saveUser')
        if(this.details.user.avatar.includes("data:image")==false){
          delete this.details.user.avatar
        }
       
        const response = await AdminService.updateUser(this.id,this.details);
        if(response.data.success == true){
          //  this.details.user=response.data.data.user
          this.details.user.avatar=response.data.data.user.avatar_urls.large
          // this.getUser()
         this.$bus.$emit('userup');
          bt.classList.add("success");
          bt.innerText = response.data.flash.notice 
         alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          setTimeout(function(){  
            bt.classList.remove("success");
            bt.innerText = "Save" 
            
          }, 500); 
          
          // 
        }
        else{
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Save" 
          }, 500); 
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
    },
    async getUser(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.editUser(this.id);
        if(response.data.success == true){
         this.details.user=response.data.data.user
         
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }
      try{
        const response = await AdminService.showUser(this.id);
        if(response.data.success == true){
         this.details.user.avatar=response.data.data.user.avatar_urls.large
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }
    },
     getThumb(event){
         const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > 4) {
          alert('File size exceeds 4 MB');
          this.$refs.thumbupload.value=null
        }
        else{
      const fr = new FileReader()
      fr.onloadend = function(){
         this.details.user.avatar =fr.result
        }.bind(this)
        
      fr.readAsDataURL(event.target.files[0])
        }
    },
  }  
}
</script>