<template>
  <div id="saved">
    <div class="row mt-4">
      <div class="col-lg-8">
        <h2>{{ presenter.name }}</h2>
      </div>
    </div>
    <div>
      <div class="mt-4" v-if="items.length == 0">
        <h3 class="text-center p-5">There are no programs available</h3>
      </div>
      <div v-else>
        <div class="mt-4">
          <label>Programs</label>
          <div class="row m-0">
            <router-link
              class="col-lg-3 pl-0"
              :to="{ name: 'Episodes', params: { id: ins.id } }"
              v-for="(ins, index) in items"
              :key="'ht' + index"
            >
              <div class="">
                <img class="w-100" :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
                <p>{{ ins.name }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="workouts" v-if="prog.length > 0">
        <div class="col-sm-12 p-0">
          <div class="row m-0">
            <h5>Other Sessions</h5>
            <button
              :disabled="prog.length > 0 && sB == 0"
              class="btn-link btn prev"
              @click="prevBook()"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button
              :disabled="prog.length > 0 && prog.length <= sB+1"
              class="btn-link btn"
              @click="nextBook()"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
        <div class="row m-0 erow w-100">
          <router-link
            class="col-lg-2 pl-0"
            :to="{ name: 'Episodes', params: { id: ins.id } }"
            v-for="(ins, index) in prog.slice(sB)"
            :key="'category' + index"
          >
            <div class="workout">
              <img :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
              <!-- <p >{{ins.name}}</p> -->
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";

export default {
  name: "ProgramPage",
  data() {
    return {
      page: "",
      sB: 0,
      items: [],
      presenter: {},
      id: "",
      plan: {},
      mid: "",
      prog: [],
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.mid = this.$session.get("user").id;
  },
  mounted() {
   
    this.getProfile();
    this.getProg();
   
  },
  computed: {},
  components: {},
  methods: {
     nextBook() {
      this.sB += 1;
    },
    prevBook() {
      this.sB -= 1;
    },
    async getPresenter() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.showCategory(this.mid);
        if (response.data.success == true) {
          this.presenter = response.data.data.category;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getProg() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getPrograms();
        if (response.data.success == true) {
          this.prog = response.data.data;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getVideos() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getPrograms(this.id);
        if (response.data.success == true) {
          this.items = response.data.data;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getProfile() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.id);
        if (response.data.success == true) {
          this.plan = response.data.data.profile.current_subscription;
          if (this.plan != null) {
            this.getPresenter();
            this.getVideos();
             this.$session.set("subscription",true)
          } else {
             this.$session.set("subscription",false)
            router.push({ path: "/customer/plans" });
          }
          // this.user.profile.avatar = response.data.data.profile.avatar.large
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
  },
};
</script>