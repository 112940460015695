<template>
  <div>
     <div class="table-list users-list mt-4 mb-4">
       <div class="row">
         <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0"><b>Coupons</b></h4>
          <router-link to="/admin/coupons/new" ><button class="form-btn ml-4">Add New</button></router-link>
          
         </div>
       </div>
      <!-- <paginate
        name="items"
        :list="coupons"
        :per="lim"
        class="pl-0"
      > -->
        
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Coupon Code</th>
              <th scope="col">Plan</th>
              <th scope="col">Coupon Limit</th>
              <th scope="col">Days Till Expired</th>
              <th scope="col">Discount</th>
            </tr>
          </thead>
          <tbody>
            <router-link  class="link_row" :to="{ name: 'EditCoupon', params: { id: coupon.id }}" v-for="(coupon,index) in coupons" :key="coupon+index">
              <tr class="table-det spacer">
                <td>{{coupon.code}}</td>
                <td><span class="mr-2" v-for="(pl,index) in coupon.plans" :key="plan+index">{{pl.name}}</span></td>
                <td>{{coupon.limit}}</td>
                <td>{{coupon.days_valid}}</td>
                <td>
                  <span v-if="coupon.discount_type=='fixed'" >$</span>
                  {{coupon.discount_value}}
                  <span  v-if="coupon.discount_type=='percentage'">%</span>
                   <font-awesome-icon  v-on:click.prevent="remItem(coupon.id)" class="float-right" :icon="['fal', 'times']" />
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>
         <ul class="pl-0 paginate-links">
     <li v-if="pages>0"  @click="getCoupons(1)" class="left-arrow disabled">
       <a>«</a>
       </li>

       <li @click="getCoupons(p)" :class="p == current ? 'number active pg' : 'number pg'" v-for="(p,index) in pages " :key="pag+index" >
       <span   v-if="Math.abs(p - current) < 4 || p == pages  || p== 1" >
         
         <a :class="{current: current=== p, last: (p == pages  && Math.abs(p- current) > 4), first:(p == 1 && Math.abs(p - current) > 4)}" >{{p}}</a>
       </span>
         </li>
       <li  v-if="pages>0" @click="getCoupons(pages)" class="right-arrow disabled"><a>»</a></li>
    </ul>
      <!-- </paginate> -->
      <!-- <paginate-links class="pl-0" for="items" :async="true" :limit="lim" :show-step-links="true"></paginate-links> -->
    </div>
  </div>
</template>

<script>
import AdminService from '../../../../services/adminService'
import axios from 'axios'

export default {
  name: 'CouponsPage',
  data () {
    return {    
      paginate: ['items'],
      lim:10,
      coupons:[],
         current:1,
      pages:0,
    }
  },
  created(){
    },
  computed:{
    
    },
  mounted(){
    this.getCoupons(this.current)
  }, 
  components: {
  },      
  methods: {
    async getCoupons(page){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.getCoupons(page);
        if(response.data.success == true){
          this.coupons = (response.data.data) 
          this.pages=   response.data.pagination.total_pages
          this.current = response.data.pagination.page          
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
      remItem(litem){
      if(confirm("Do you really want to delete?")){
        this.remove(litem)
      }
    },
    async remove(it){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.deleteCoupon(it);
        if(response.data.success == true){
          this.getCoupons()
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>