<template>
  <div id="new_user" class="col-lg-11 mt-4">
    <div class="col-lg-12">
    <h3><b>Edit Category</b></h3>
      <form class="" @submit.prevent="createCategory">
         <div class="mt-4">
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6">
              <label>Name</label>
              <input type="text" v-model="details.category.name"  class="input-field form-control" placeholder="" required />
            </div>
              <div class="col-lg-3 col-md-6">
               <label>Tags</label>  
              <v-select :options="tags"  :multiple="true" v-model="details.category.tag_ids" :reduce="label=>label.id " label="label" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <label>Thumbnail</label>
              <br/>
              <input type="file" @change="getThumb" ref="thumbupload" />
            </div>
              <div class="col-lg-12 mt-2">
                <img  class="av" v-if="details.category.thumbnail" :src="details.category.thumbnail" />
             </div>
           </div>
                                       <router-link to="/admin/categories" ><button class="form-btn mr-4 cancel">Cancel</button></router-link>

          <button  id="saveSess" type="submit" class="form-btn ">Update Category</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'
import router from '@/router'

export default {
  name: 'EditCategoryPage',
  data () {
    return {    
      details:{
        category:{
           start_at:"",
          end_at:"",
          published:"",
          name:"",
          tag_ids:[],
          thumbnail:""
        }
      },
      id:"",
      u12:true,
      tags:[]
    }
  },
  created(){
    this.id=this.$route.params.id
  
  },
  computed:{
    
    },
  mounted(){
    this.getCategory();
  }, 
  components: {
  },      
  methods: {
      async createCategory(){
        var alert= {}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        var bt= document.getElementById('saveSess')
        if(this.details.category.thumbnail.includes("data:image")==false){
          delete this.details.category.thumbnail
        }
        const response = await AdminService.updateCategory(this.id,this.details);
        if(response.data.success == true){
          ////bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          // //bt.innerText = response.data.flash.notice  
            setTimeout(function(){  
          router.push({ path: '/admin/categories' }); 
      }, 500); 
        }
        else{
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Update Category" 
          }, 1500); 
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
    },
    async getCategory(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.editCategory(this.id);
        
        if(response.data.success == true){
         this.details.category=response.data.data.category
         this.tags= (response.data.data.options.tags)
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }

        try{
        const response = await AdminService.showCategory(this.id);
        if(response.data.success == true){
         this.details.category.thumbnail=response.data.data.category.thumbnail_urls.large
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }
    },
     getThumb(event){
         const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > 4) {
          alert('File size exceeds 4 MB');
          this.$refs.thumbupload.value=null
        }
        else{
      const fr = new FileReader()
      fr.onloadend = function(){
         this.details.category.thumbnail =fr.result
        }.bind(this)
        
      fr.readAsDataURL(event.target.files[0])
        }
    },
  }  
}
</script>