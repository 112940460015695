<template>
  <div>
    <div class="log-form login">
      <form @submit.prevent="loginUser">
        <div class="form-det">
          <h2>Login</h2>
          <input type="email" v-model="auth.session.login" class="input-field form-control" placeholder="Email" required/>
          <input :type="passwordFieldType" v-model="auth.session.password" class="input-field form-control" placeholder="Password" required/>
          <span type="password" @click="switchVisibility"><i class="far fa-eye"></i></span>
          <router-link to="/forgot-password" class=" col-sm-12 text-right p-0 small-link">Forgot Password?</router-link>
          <button id="logBtn" type="submit" class="form-btn">Log In</button>
          <p class="text-center">Don't have an account? <router-link to="/signup" class="link"><u><strong>Sign up</strong></u></router-link></p>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import custom from '../../javascript/custom'
import AuthService from '../../services/authService'
import router from '@/router'

export default {
  name: 'LoginPage',
  data () {
    return { 
      auth:{
        session:{
          password:"",
          login:""
        }
      },
      passwordFieldType: 'password'
    }
  },
  created(){
    var sess = localStorage.getItem("vue-session-key")
    if(sess && sess != '{}' && sess != undefined){
      var user = JSON.parse(sess)
      user=user.user
      // console.log(user)
      if(user && user.role_symbols.length==0){
            router.push({ path: '/customer/dashboard' });
            }
            else if(user && user.role_symbols.length>0){

              for(var i=0;i<user.role_symbols.length;i++){
                if(user.role_symbols[i]=='admin' || user.role_symbols[i]=='instructor'){
                  router.push({ path: '/admin/users' }); 
                }
                else{
                  router.push({ path: '/customer/dashboard' });  
                }
              }
            }
          
    }
    else{

      if(this.$route.name != 'Login'){

        router.push({
                name:'Login'
             })
      }
    }
  },

  computed:{

  },  
  components: {
  },      
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      if (this.passwordFieldType == 'text'){
        custom.showPassword(0)
      }
      else{
        custom.hidePassword(0)
      }
    },
     async loginUser(){
       var alert= {}
      try{
        const response = await AuthService.getUser(this.auth);
        var bt= document.getElementById('logBtn')
        if(response.data.success == true){
          this.$session.start()
          this.$session.set("token",(response.data.data.record.authToken))
          sessionStorage.setItem('token',response.data.data.record.authToken)
          this.$session.set("loggedIn",true)
          this.$session.set("user",response.data.data.record)
          sessionStorage.setItem('user',JSON.stringify(response.data.data.record.role_symbols))
          ////bt.classList.add("success");
          // bt.innerText = "Logged In"
            alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          setTimeout(function(){  
            
          if(response.data.data.record.role_symbols.length==0){
            router.push({ path: '/customer/dashboard' });
            }
            else{
              for(var i=0;i<response.data.data.record.role_symbols.length;i++){
                if(response.data.data.record.role_symbols[i]=='admin' || response.data.data.record.role_symbols[i]=='instructor'){
                  router.push({ path: '/admin/users' }); 
                }
                else{
                  router.push({ path: '/customer/dashboard' });  
                }
              }
            }
           }, 500); 
           
          

        }
        else{
          this.$session.set("loggedIn",false)
          bt.classList.add("fail");
          bt.innerText = "Please Try Again"    
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Log In" 
          }, 1500);  
          alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert);   
        }
      } catch (err){
        //console.log(err)
      }
      
    }
  }  
}
</script>