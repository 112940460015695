<template>
  <div id="sess-layout" class="wf-back">
     <div  v-if="salert"
              class="alert wal alert-dismissible fade show"
              :class="alert.type"
              role="alert"
              :style="alert.type ? 'display:block;position:fixed ' : 'display:none'"
            >
            <div id="alert" >
              <p class="alert-heading m-0">
                <strong>{{ alert.head }}</strong>
                <span class="alert-text ml-2">{{ alert.text }}</span>
              </p>
            </div>
            </div>
    <div class="sesspg">
    <div class="sesslay">

   <div class="sess-det">
      <img class="sess-logo" src="../../assets/wellfit-logo@2x.png" />
      <router-view/>
    </div>
    </div>

    </div>
    <div id="sess-footer">
      <div class="foot-details">
        <span class="pby">powered by</span>
        <img class="powered-logo" src="../../assets/Canopy-Logo.png" />
        <p class="mb-0 d-inline-block ml-3 res">
          2020 WellFit Ltd. All Rights Reserved.
          <br>
          <a href="/">Privacy Policy </a>/ <a href="/">Terms of Use</a>
        <a class="ogs" href="https://www.onegreatstudio.com/" target="_blank">
          <img class="" src="../../assets/1gs-logo@2x.png" />
        </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SessionPage',
  data () {
    return {    
      alert:{
        text:"",
        head:"",
        type:""
      },
       window: {
            width: 0,
            height: 0
        },
        salert:false
    }
  },
  created(){
  
  },
  computed:{

  }, 
  mounted(){
   this.$bus.$on('flash',(data)=>{
     this.alert.text=data.text;
     if(this.alert.text[0]!=""){
this.alert.text=this.alert.text[0]
      }
     this.alert.head=data.head;
     this.alert.type=data.type
      this.salert=true;
        setTimeout(function () {
                  if( document.getElementById("alert") != null){
             document.getElementById("alert").classList.remove("d-block");
           }
        this.removeAl()
      }.bind(this), 5000);
   })

  },
  components: {
   
  },      
  methods: {
  
     removeAl(){
      this.salert=false;
      delete this.alert.type
    },

  }  
}
</script>