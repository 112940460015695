<template>
<div class="main">
  
  <center>
     <!-- <img class="admin-logo" src="../assets/wellfit-logo@2x.png" style="width:300px;margin-bottom:3em" /> -->
    <h1 class="error">404</h1>
    <h1>This page does not exist</h1>
    <p>
      <a @click=" back()">Back</a>
    </p>
  </center>
</div>
</template>

<script>

  export default {
    name: 'NotFound',
    methods:{
      back:function(){
        window.history.back()
      }
    }
  }

</script>

<style >
html,.main{
  background:#101010;
  color:white;
}
  center {
    margin: 15vw;
    
  }

  h1 {
    color: var(--border);
    font-size: 2em;
  }

  .error{
    font-size:64px;
  }

  a{
    color:white !important;
  }

</style>
