<template>
  <div>
    <div class="log-form forgot-form">
      <form @submit.prevent="activateUser">
        <div class="form-det">
          <h2>Activate Account</h2>
          <input type="email" v-model="auth.user.login" class="input-field form-control" placeholder="Email" required/>
          <input  v-model="auth.user.pin" class="input-field form-control" placeholder="Pin" required/>
          <button id="logBtn" type="submit" class="form-btn">Activate</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import AuthService from '../../services/authService'
import router from '@/router'

export default {
  name: 'ActivationPage',
  data () {
    return { 
      auth:{
        user:{
          pin:"",
          login:""
        }
      },
    }
  },
  created(){
  
  },
  mounted(){
    this.$bus.$on('acuser', (data) => {
      this.auth.user.login = data
  })
  },
  computed:{

  },  
  components: {
  },      
  methods: {
    async activateUser(){
      var alert= {}
      try{
        const response = await AuthService.getActivate(this.auth);
        var bt= document.getElementById('logBtn')
        if(response.data.success == true){
         //bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          bt.innerText =response.data.flash.notice   
          // router.push({ path: '/login' }); 
            this.$session.start()
          this.$session.set("token",(response.data.data.record.authToken))
          sessionStorage.setItem('token',response.data.data.record.authToken)
          this.$session.set("loggedIn",true)
          this.$session.set("user",response.data.data.record)
          sessionStorage.setItem('user',JSON.stringify(response.data.data.record.role_symbols))
         setTimeout(function(){  
   
       
          if(response.data.data.record.role_symbols.length==0 ||response.data.data.record.role_symbols==null ){
              router.push({ path: '/customer/dashboard' });
            }
            else{
              for(var i=0;i<response.data.data.record.role_symbols.length;i++){
                if(response.data.data.record.role_symbols[i]=='admin' || response.data.data.record.role_symbols[i]=='instructor'){
                  router.push({ path: '/admin/users' }); 
                }
                else{
                  router.push({ path: '/customer/dashboard' });  
                }
              }
            }
      }, 500);

        }
        else{
          bt.classList.add("fail");
          bt.innerText = "Please Try Again"    
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Activate" 
          }, 1500);   
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert);   
        }
      } catch (err){
        //console.log(err)
      }
      
    }
  }  
}
</script>