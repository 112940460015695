<template>
  <div>
    <div class="log-form forgot-form">
      <form @submit.prevent="forgotPass">
      <div class="form-det">
        <h2>Reset Password</h2>
        <!-- <input type="email" v-model="auth.user.login" class="input-field form-control" placeholder="Email" required/>
        <input  v-model="auth.user.pin" class="input-field form-control" placeholder="Pin" required/> -->
        <input :type="passwordFieldType" v-on:keyup="validate"  v-on:keydown="validate" v-model="auth.user.password" class="input-field form-control" placeholder="Password" required minlength="8"/>
        <span type="password" @click="switchVisibility"><i class="far fa-eye"></i></span>
        <input :type="confirmpasswordFieldType"  v-on:keyup="validate"  v-on:keydown="validate" v-model="confirm_password" class="input-field form-control" placeholder="Confirm Password" required minlength="8"/>
        <span type="password" @click="switchVisibilityconfirm"><i class="far fa-eye"></i></span>
        <span class="form-helper nomatch">
          Password must match the one typed before.                    
        </span>
        <button id="forbtn" type="submit" class="form-btn">Reset Password</button>
      </div>
      </form>
    </div>
  </div>
</template>
<script>
import custom from '../../javascript/custom'
import AuthService from '../../services/authService'
import router from '@/router'

export default {
  name: 'ResetPage',
  props: ['user'],
  data () {
    return {    
      auth:{
        user:{
          password:"",
          pin:"",
          login:""
        }
      },
      confirm_password:"",
      passwordFieldType: 'password',
      confirmpasswordFieldType: 'password'
    }
  },
  created(){
    
  },
  computed:{

  },
  mounted(){
    // this.auth.user.login=this.$route.params.user
    var urlParams = new URLSearchParams(window.location.search);
      this.auth.user.login = urlParams.get('email')
      this.auth.user.pin = urlParams.get('pin')
  }, 
  components: {
  },      
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      if (this.passwordFieldType == 'text'){
        custom.showPassword(0)
      }
      else{
        custom.hidePassword(0)
      }
    },
     switchVisibilityconfirm() {
      this.confirmpasswordFieldType = this.confirmpasswordFieldType === 'password' ? 'text' : 'password'
      if (this.confirmpasswordFieldType == 'text'){
        custom.showPassword(1)
      }
      else{
        custom.hidePassword(1)
      }
    },
    validate(){
      if(this.auth.user.password != ""){
        if(this.auth.user.password != this.confirm_password){
          custom.validatePassword(false)
        }
        else{
          custom.validatePassword(true)
        }
      }
    },

    async forgotPass(){
      var alert= {}
      try{
        const response = await AuthService.resetPassword(this.auth);
        var bt= document.getElementById('forbtn')
        if(response.data.success == true){
         //bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          //bt.innerText = response.data.flash.notice  
           setTimeout(function(){  
  
       
          router.push({ path: '/login' });  
      }, 500);
        }
        else{
          bt.classList.add("fail");
          bt.innerText = "Please Try Again"  
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Reset Password" 
          }, 1500);   
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert);     
        }
      } catch (err){
        //console.log(err)
      }
      
    }
  }  
}
</script>