<template>
  <div id="saved">
    <div>
      <div>
        <iframe width="100%" height="640" :src="video" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
        <!-- <p class="d-inline-block mb-2">{{presenter.title}} {{moment(presenter.start_at).format('LT')}} {{moment.tz(presenter.start_at).format('z')}}
              </p> -->
        <p class="d-inline-block mb-2 w-100 vidti">{{presenter.title}} 
                                    {{
                            moment(presenter.start_at)
                              .tz(moment.tz.guess(true))
                              .format("dddd MMMM DD, YYYY h:mmA z")
                          }}
          </p>
        <!-- <span class="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../assets/live.png"/></span> -->
      

        <span class="live" v-if="presenter.scheme=='live'"> <img class="live-md"
            src="../../../assets/live.png" /></span>
        <p class="vidins">- with {{presenter.presenter.name}}</p>
        <p class="addsaved" v-if="presenter.bookmarked==false" v-on:click.prevent="addVideo(presenter.id)">
          <font-awesome-icon :icon="['far', 'bookmark']" class="mr-2" />
          <span class="bookp">Add to saved</span>
        </p>
        <p v-else class="remsaved" v-on:click.prevent="removeVideo(presenter.id)">
          <font-awesome-icon :icon="['fas', 'bookmark']" class="mr-2" />
           <span class="bookp">Remove from saved </span>
        </p>
      </div>
      <div class="workouts" v-if="items.length>0">
        <div class="col-sm-12  p-0">
          <div class="row m-0">
            <h5>Recorded Workout Sessions</h5>
            <div v-if="items.length>0">

              <button :disabled="(items.length>0 && sB==0)" class="btn-link btn prev" @click="prevBook()"><i
                  class="fas fa-chevron-left"></i></button>
              <button :disabled="(items.length>0 && (items.length<=eB))" class="btn-link btn " @click="nextBook()"><i
                  class="fas fa-chevron-right"></i></button>

            </div>
          </div>
        </div>
        <div class="row m-0 erow w-100">
          <router-link class="col-lg-2" :to="{ name: 'Episodes', params: { id: ins.id }}"
            v-for="(ins,index) in items.slice(sB, eB)" :key="'category'+index">
            <div class="workout">
              <img :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
              <!-- <p >{{ins.name}}</p> -->
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import custom from '../../javascript/custom'
  import UserService from '../../../services/userService'
  import axios from 'axios'
  import router from '@/router'

  export default {
    name: 'EpisodePage',
    data() {
      return {
        page: "",
        sB: 0,
        eB: 6,
        items: [],
        presenter: {},
        id: "",
        video: "",
        plan: {},
        mid: "",
          window: {
            width: 0,
            height: 0
        },
        small:1,
        medium:2,
        large:6
      }
    },
    created() {
      this.id = this.$route.params.id
      this.mid = this.$session.get("user").id

    },
    mounted() {
      this.window.width = window.innerWidth;
      this.getProfile()
   if (this.window.width <= 564) {
          this.eB = this.small
        } else if (this.window.width > 564 && this.window.width <= 991) {
          this.eB = this.medium
        } 
        else {
          this.eB = this.large;
        }
    },
    computed: {

    },
    components: {

    },
    methods: {
      nextBook() {
       if (this.window.width <= 564) {
          this.eB += this.small
          this.sB += (this.small)
        } else if (this.window.width > 564 && this.window.width <=991) {
          this.eB += this.medium
          this.sB += (this.medium-1)
        } else {
          this.sB += (this.large-1);
          this.eB += this.large

        }
      },
      prevBook() {
        if (this.window.width <= 564) {
          this.eB -= this.small
          this.sB -= (this.small)
        } else if (this.window.width > 564 && this.window.width <=991) {
          this.eB -= this.medium
          this.sB -= (this.medium-1)
        } else {
          this.sB -= (this.large-1);
          this.eB -= this.large

        }
      },

      async getProfile() {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get("token")
        try {
          const response = await UserService.getProfile(this.id);
          if (response.data.success == true) {
            this.plan = (response.data.data.profile.current_subscription)
            if (this.plan != null) {
              this.getPresenter()
              this.getVideos()
               this.$session.set("subscription",true)
            } else {
               this.$session.set("subscription",false)
              router.push({
                path: '/customer/plans'
              });
            }
            // this.user.profile.avatar = response.data.data.profile.avatar.large
          } else {
            //console.log("error")
          }
        } catch (err) {
          //console.log(err)
        }

      },
      async getPresenter() {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get("token")
        try {
          const response = await UserService.getPrograms();
          if (response.data.success == true) {
            this.items = (response.data.data)
          } else {
            //console.log("error")
          }
        } catch (err) {
          //console.log(err)
        }
      },
      async getVideos() {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get("token")
        try {
          const response = await UserService.showSession(this.id);
          if (response.data.success == true) {
            this.presenter = (response.data.data.session)
            this.video = this.presenter.content_url
            this.getPlayer()
          } else {
            //console.log("error")
          }
        } catch (err) {
          //console.log(err)
        }
      },
      getPlayer() {
        if (this.video.startsWith("https://vimeo.com")) {
          this.video = this.video.split(".com/")
          // alert(this.video[1])
          this.video = "https://player.vimeo.com/video/" + this.video[1]
        } else if (this.video.startsWith("https://player.vimeo.com")) {
          this.video
        }
      },
      async addVideo(bid) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get("token")
        try {
          const response = await UserService.saveSession(bid);
          if (response.data.success == true) {
            // this.books = (response.data.data)         
            this.getProfile()
          } else {
            //console.log("error")
          }
        } catch (err) {
          //console.log(err)
        }
      },
      async removeVideo(bid) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get("token")
        try {
          const response = await UserService.removeSession(bid);
          if (response.data.success == true) {
            // this.books = (response.data.data)   
            this.getProfile()
          } else {
            //console.log("error")
          }
        } catch (err) {
          //console.log(err)
        }
      },
    }
  }
</script>