export default{
  state:{
    authToken:"",
    loggedIn:false
  },
  mutations:{
    setAuthToken(state,data){
      state.authToken=data;
      state.loggedIn = true
    }
  },
  actions:{
    setAuthToken({commit},data){
      commit('setAuthToken',data)
    },
  },
  getters: {
    token: state => {
      return state.authToken
    }
  }
}