<template>
  <div id="new_user" class="col-lg-11 mt-4 adacc">
    <h3><b>Edit Role</b></h3>
    <div class="">
      <form @submit.prevent="updateRole">
        <div class="mt-4">
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6">
              <label>Role Name *</label>
              <input type="text" v-model="details.role.label" class="input-field form-control" placeholder="" required />
            </div>         
          </div>
                              <router-link to="/admin/roles" ><button class="form-btn mr-4 cancel">Cancel</button></router-link>

          <button type="submit" id="saveRole" class="form-btn ">Update Role</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from '../../../../services/adminService'
import axios from 'axios'
import router from '@/router'

export default {
  name: 'EditRolePage',
  data () {
    return {    
      details:{
        role:{
          label:"",
          id:""
        }
      },
      id:""
    }
  },
  created(){
    this.id=this.$route.params.id
  },
  computed:{
    
    },
  mounted(){
    this.getRole()
 
  }, 
  components: {
  },      
  methods: {
     async updateRole(){
       var alert= {}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        var bt= document.getElementById('saveRole')
        const response = await AdminService.updateRole(this.id,this.details);
        if(response.data.success == true){
         //bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          //bt.innerText = response.data.flash.notice  
           setTimeout(function(){  
             router.push({ path: '/admin/roles' }); 

       
      }, 500);
        }
        else{
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Update Role" 
          }, 1500); 
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
    },
    async getRole(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.editRole(this.id);
        if(response.data.success == true){
         this.details.role=response.data.data.role
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>