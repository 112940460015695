var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-det"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('h4',{staticClass:"mb-3"},[_vm._v("Schedule")]),_c('div',[_c('div',{staticClass:"mb-5 dayblk"},_vm._l((_vm.days),function(group,start_at){return _c('div',{key:'wk' + start_at},[_c('h5',{staticClass:"day"},[_vm._v(_vm._s(_vm.moment(start_at).format("dddd")))]),_vm._l((group),function(ins,index){return _c('div',{key:'day' + index},[(
                  ins.content_host_type == 'VimeoVideo' ||
                  ins.content_host_type == 'WellFitVideo'
                )?_c('router-link',{attrs:{"to":{ name: 'ShowEpisode', params: { id: ins.id } }}},[_c('div',{staticClass:"row w-100 sess mb-3"},[_c('div',{staticClass:"col-lg-2 presimg"},[_c('img',{staticClass:"insimg",attrs:{"src":ins.presenter != null ? ins.presenter.avatar_urls.large : ''}})]),_c('div',{staticClass:"col-lg-10 dayinfo daysess"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-7 pl-0"},[(ins.title != null)?_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(ins.title))]):_vm._e(),(ins.presenter != null)?_c('p',{staticClass:"mb-0 presn"},[_vm._v(" "+_vm._s(ins.presenter.name)+" ")]):_vm._e()]),_c('div',{staticClass:"col-lg-2 p-0"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(ins.start_at.format("h:mma z"))+" "),_c('br'),_c('span',[_vm._v("Start Time")])])]),_c('div',{staticClass:"col-lg-3 p-0 text-right"},[(
                  ins.content_host_type == 'VimeoVideo' ||
                  ins.content_host_type == 'WellFitVideo'
                )?_c('router-link',{attrs:{"to":{ name: 'ShowEpisode', params: { id: ins.id } }}},[_c('span',{staticClass:"jclass"},[_vm._v("Join Class "),_c('i',{staticClass:"far fa-play-circle ml-2"})])]):_vm._e()],1)])]),_c('div',{staticClass:"col-xs-12 text-right jmob"},[(
                  ins.content_host_type == 'VimeoVideo' ||
                  ins.content_host_type == 'WellFitVideo'
                )?_c('router-link',{attrs:{"to":{ name: 'ShowEpisode', params: { id: ins.id } }}},[_c('span',{staticClass:"jclass"},[_vm._v("Join Class "),_c('i',{staticClass:"far fa-play-circle ml-2"})])]):_vm._e()],1)])]):_c('a',{attrs:{"target":"_blank","href":ins.content_url}},[_c('div',{staticClass:"row w-100 sess mb-3"},[_c('div',{staticClass:"col-lg-2 presimg"},[_c('img',{staticClass:"insimg",attrs:{"src":ins.presenter != null ? ins.presenter.avatar_urls.large : ''}})]),_c('div',{staticClass:"col-lg-10 dayinfo daysess"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-7 pl-0"},[(ins.title != null)?_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(ins.title))]):_vm._e(),(ins.presenter != null)?_c('p',{staticClass:"mb-0 presn"},[_vm._v(" "+_vm._s(ins.presenter.name)+" ")]):_vm._e()]),_c('div',{staticClass:"col-lg-2 p-0"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(ins.start_at.format("h:mma z"))+" "),_c('br'),_c('span',[_vm._v("Start Time")])])]),_c('div',{staticClass:"col-lg-3 p-0 text-right"},[_c('a',{attrs:{"target":"_blank","href":ins.content_url}},[_c('span',{staticClass:"jclass"},[_vm._v("Join Class "),_c('i',{staticClass:"far fa-play-circle ml-2"})])])])])]),_c('div',{staticClass:"col-xs-12 text-right jmob"},[_c('a',{attrs:{"target":"_blank","href":ins.content_url}},[_c('span',{staticClass:"jclass"},[_vm._v("Join Class "),_c('i',{staticClass:"far fa-play-circle ml-2"})])])])])])],1)})],2)}),0)])]),_c('div',{staticClass:"col-lg-4"},[_c('h4',[_vm._v("Here is Today’s WellFit Affirmation")]),(_vm.settings.affirmation != null)?_c('div',{staticClass:"affblk mb-5 mt-3"},[_vm._v(" "+_vm._s(_vm.settings.affirmation)+" ")]):_vm._e(),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"/WELLFIT WEEKLY HABIT TRACKER.pdf","target":"_blank"}},[_c('div',{staticClass:"tracker"})])}]

export { render, staticRenderFns }