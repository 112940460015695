<template>
  <div id="new_user" class="col-lg-11 mt-4 adacc">
    <div class="col-lg-12">
      <h3><b>New Setting</b></h3>
      <form class="" @submit.prevent="createSetting">
        <div class="mt-4">
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6">
              <label>Affirmation</label>
              <textarea
                type="text"
                v-model="details.settings.affirmation"
                class="input-field form-control"
                placeholder=""
                required
                rows="6"
              ></textarea>
            </div>
          </div>
          <router-link to="/admin/settings"
            ><button class="form-btn mr-4 cancel">Cancel</button></router-link
          >

          <button id="saveSess" type="submit" class="form-btn">
            Save Setting
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";
import router from "@/router";

export default {
  name: "SettingsPage",
  data() {
    return {
      details: {
        settings: {
          affirmation: "",
        },
      },
      u12: true,
    };
  },
  created() {},
  computed: {},
  mounted() {},
  components: {},
  methods: {
    async createSetting() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("saveSess");
        const response = await AdminService.createSettings(this.details);
        if (response.data.success == true) {
          //bt.classList.add("success");
          alert = {
            text: [],
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          //bt.innerText = response.data.flash.notice
          setTimeout(function () {
            router.push({ path: "/admin/settings" });
          }, 500);
        } else {
          //console.log("error");
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Save Tag";
          }, 1500);
          alert = {
            text: response.data.errors[0],
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
  },
};
</script>