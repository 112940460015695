<template>
  <div id="new_user" class="col-lg-11 mt-4 adacc">
    <div class="col-lg-12">
    <h3><b>Edit Tag</b></h3>
      <form class="" @submit.prevent="createTag">
         <div class="mt-4">
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6">
              <label>Name</label>
              <input type="text" v-model="details.tag.label"  class="input-field form-control" placeholder="" required />
            </div>
          </div>
             <div  class="row mb-3">
               <div class="col-lg-6 col-md-6">
                       <label>Promotional Tag</label>
                        <input type="checkbox" v-model="details.tag.promo" class="ml-3" placeholder="" />
                 </div>
          </div>
                              <router-link to="/admin/tags" ><button class="form-btn mr-4 cancel">Cancel</button></router-link>

          <button  id="saveSess" type="submit" class="form-btn ">Update Tag</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from '../../../services/adminService'
import axios from 'axios'
import router from '@/router'

export default {
  name: 'EditTagPage',
  data () {
    return {    
      details:{
        tag:{
          published:"",
          name:"",
          promo:false
        }
      },
      id:"",
      u12:true
    }
  },
  created(){
    this.id=this.$route.params.id
  
  },
  computed:{
    
    },
  mounted(){
    this.getTag();
  }, 
  components: {
  },      
  methods: {
      async createTag(){
        var alert= {}
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        var bt= document.getElementById('saveSess')
        const response = await AdminService.updateTag(this.id,this.details);
        if(response.data.success == true){
         //bt.classList.add("success");
           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          //bt.innerText = response.data.flash.notice  
           setTimeout(function(){  
      
       
          router.push({ path: '/admin/tags' }); 
      }, 500);
        }
        else{
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error
           setTimeout(function(){  
            bt.classList.remove("fail");
            bt.innerText = "Update Tag" 
          }, 1500); 
           alert = {text:response.data.errors,head:response.data.flash.error,type:"alert-danger d-block"}  
            this.$bus.$emit('flash',alert); 
        }
      } catch (err){
        //console.log(err)
      }
    },
    async getTag(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await AdminService.editTag(this.id);
        if(response.data.success == true){
         this.details.tag=response.data.data.tag
        }
        else{
          //console.log("error")
       
        }
      } catch (err){
        //console.log(err)
      }
    },
  }  
}
</script>