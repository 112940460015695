<template>
  <div id="saved">
    <div class="row mt-4">
      <div class="col-lg-8">
        <h2>{{ presenter.name }}</h2>
        <h6>- Instructor Videos</h6>
      </div>
    </div>
    <div>
      <div class="mt-4" v-if="items.length == 0">
        <h3 class="text-center p-5">This instructor has no videos</h3>
      </div>
      <div v-else>
        <div class="mt-4">
          <h5>Videos</h5>
          <!-- <paginate name="items" :list="items" :per="lim" class="w-100 p-0"> -->
            <div class="row m-0 pgblock">
              <div
                v-for="(ins, index) in items"
                :key="'ht' + index"
                class="htod col-lg-4 col-md-6 pl-0"
              >
                <router-link
                  v-if="
                    ins.content_host_type == 'VimeoVideo' ||
                    ins.content_host_type == 'WellFitVideo'
                  "
                  :to="{ name: 'ShowEpisode', params: { id: ins.id } }"
                >
                  <div class="">
                    <img class="w-100" :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
                    <p class="d-inline-block w-95">
                      {{ ins.title }}
                      {{
                        moment(ins.start_at)
                          .tz(moment.tz.guess(true))
                          .format("dddd MMMM DD, YYYY h:mmA z")
                      }}
                    </p>
                    <font-awesome-icon
                      class="float-right probook"
                      v-if="ins.bookmarked == false"
                      v-on:click.prevent="addVideo(ins.id)"
                      :icon="['far', 'bookmark']"
                    />
                    <font-awesome-icon
                      class="float-right probook"
                      v-else
                      v-on:click.prevent="removeVideo(ins.id)"
                      :icon="['fas', 'bookmark']"
                    />
                    <!-- <span class="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../assets/live.png"/></span> -->
                  </div>
                </router-link>
                <a v-else target="_blank" :href="ins.content_url">
                  <div class="">
                    <img class="w-100" :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
                    <!-- <p class="d-inline-block w-95">{{ins.title}} {{moment(ins.start_at).format('LT')}} {{moment.tz(ins.start_at).format('z')}}
              </p> -->
                    <p class="d-inline-block w-95">
                      {{ ins.title }}
                      {{
                        moment(ins.start_at)
                          .tz(moment.tz.guess(true))
                          .format("dddd MMMM DD, YYYY h:mmA z")
                      }}
                    </p>
                    <!-- <span class="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../assets/live.png"/></span> -->
                    <font-awesome-icon
                      class="float-right probook"
                      v-if="ins.bookmarked == false"
                      v-on:click.prevent="addVideo(ins.id)"
                      :icon="['far', 'bookmark']"
                    />
                    <font-awesome-icon
                      class="float-right probook"
                      v-else
                      v-on:click.prevent="removeVideo(ins.id)"
                      :icon="['fas', 'bookmark']"
                    />
                    <!-- <span class="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../assets/live.png"/></span> -->
                  </div>
                </a>
              </div>
            </div>
          <!-- </paginate>
          <paginate-links
            class="progpag inspag pl-0"
            for="items"
            :async="true"
            :limit="lim"
            :show-step-links="true"
          >
          </paginate-links> -->
            <ul class="pl-0 progpag inspag items paginate-links">
     <li v-if="pages>0"  @click="getVideos(1)" class="left-arrow disabled">
       <a>«</a>
       </li>

       <li @click="getVideos(p)" :class="p == current ? 'number active pg' : 'number pg'" v-for="(p,index) in pages " :key="pag+index" >
       <span   v-if="Math.abs(p - current) < 4 || p == pages  || p== 1" >
         
         <a :class="{current: current=== p, last: (p == pages  && Math.abs(p- current) > 4), first:(p == 1 && Math.abs(p - current) > 4)}" >{{p}}</a>
       </span>
         </li>
       <li  v-if="pages>0" @click="getVideos(pages)" class="right-arrow disabled"><a>»</a></li>
    </ul>
        </div>
      </div>

      <div class="workouts">
        <div class="col-sm-12 p-0">
          <div class="row m-0">
            <h5>Other Sessions</h5>
            <button
              :disabled="sessions.length > 0 && sB == 0"
              class="btn-link btn prev"
              @click="prevBook()"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button
              :disabled="sessions.length > 0 && sessions.length <= sB+1"
              class="btn-link btn"
              @click="nextBook()"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
        <div class="row m-0 erow w-100">
          <router-link
            class="col-lg-2 pl-0"
            :to="{ name: 'Episodes', params: { id: ins.id } }"
            v-for="(ins, index) in sessions.slice(sB)"
            :key="'category' + index"
          >
            <div class="workout">
              <img :src="ins.thumbnail_urls != null?  ins.thumbnail_urls.large : '' " />
              <!-- <p >{{ins.name}}</p> -->
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";

export default {
  name: "InstructorPage",
  data() {
    return {
      page: "",
      sB: 0,
      items: [],
      paginate: ["items"],
      // lim: 12,
      sessions: [],
      presenter: {},
      id: "",
      mid: "",
      plan: {},
      current:1,
      pages:0,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.mid = this.$session.get("user").id;
  },
  mounted() {
    this.getProfile();
  
  },
  computed: {},
  components: {},
  methods: {
     nextBook() {
      this.sB += 1;
    },
    prevBook() {
      this.sB -= 1;
    },
    async getPresenter() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.showPresenter(this.id);
        if (response.data.success == true) {
          this.presenter = response.data.data;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getSessions() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getPrograms();
        if (response.data.success == true) {
          this.sessions = response.data.data;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getVideos(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getInsVideos(this.id,page);
        if (response.data.success == true) {
          this.items = response.data.data;
              this.pages=   response.data.pagination.total_pages
          this.current = response.data.pagination.page 
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getProfile() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.mid);
        if (response.data.success == true) {
          this.plan = response.data.data.profile.current_subscription;
          if (this.plan != null) {
            this.getPresenter();
            this.getVideos(this.current);
            this.getSessions();
             this.$session.set("subscription",true)
          } else {
             this.$session.set("subscription",false)
            router.push({
              path: "/customer/plans",
            });
          }
          // this.user.profile.avatar = response.data.data.profile.avatar.large
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async addVideo(bid) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.saveSession(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getVideos(this.current);
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async removeVideo(bid) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.removeSession(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getVideos(this.current);
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
  },
};
</script>